import { CST } from "../Helper/CST";
import { Extention } from "../Helper/Extension";
import LoadingBar from "../Helper/LoadingBar"

export default class SplashScene extends Phaser.Scene {
    constructor() {
        super("splash")
    }
   init() {
        //  Inject our CSS
        var element = document.createElement('style');
        document.head.appendChild(element);
        var sheet = element.sheet;
        var styles = '@font-face { font-family: "SwisBlack"; src: url("assets/font/SwisBlack.ttf") format("opentype"); }\n';
        sheet.insertRule(styles, 0);
    }

    preload() {
        this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
 
        new LoadingBar(this)

        this.entry = []
        this.outro = []
        for(let i =0 ; i <= 43; ++i) {
            let number = "00000"
            let numberEnding = i.toString()
            number += numberEnding
            number = number.substr(numberEnding.length,5)

            this.entry.push("Intro_"+number)

            this.load.image("Intro_"+number,"./assets/images/Splash Screen/Entry/Intro_"+number+".png")
        }

        for(let i =0 ; i <= 10; ++i) {
            let number = "00000"
            let numberEnding = i.toString()
            number += numberEnding
            number = number.substr(numberEnding.length,5)

            this.outro.push("Outro_"+number)

            this.load.image("Outro_"+number,"./assets/images/Splash Screen/Outro/Outro_"+number+".png")
        }
    }

    create() {
        //this.scene.start("home")

        WebFont.load({
            custom: {
                families: ['SwisBlack']
            }
        });
        const frames = []
       this.entry.forEach(img => {
        frames.push({key:img, frame:null})
       })

        this.anims.create({
            key: 'entry',
            frames: frames,
            frameRate: 12
        });

          const outroFrames = []
        this.outro.forEach(img => {
         outroFrames.push({key:img, frame:null})
        })
 
         this.anims.create({
             key: 'outro',
             frames: outroFrames,
             frameRate: 12
         });

  

       const img = this.add.sprite(this.game.renderer.width/2, this.game.renderer.height/2, this.entry[0])
       .play('entry').once("animationcomplete",()=>{
        this.time.delayedCall(2000, ()=>{
            img.play("outro").once("animationcomplete",()=>{
                this.scene.start("home")
            })
        })
       });
  

    }

    reset() {

    }
}
