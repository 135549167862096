/** @type {import ("../../typing/phaser")} */
/** @type {import ("../../typing/SpinePlugin")} */
/** @type {import ("../../typing/spine")} */

import { CST } from "../Helper/CST";
import { CharacterDialogData } from "../Scripts/CharacterDialogData";
import { SpineCharacterData } from "../Scripts/SpineCharacterData";

export class LoadScene extends Phaser.Scene {
    constructor() {
        super({
            key: CST.SCENE.LOAD_SCENE,
            pack: {
                files: [
                    { type: 'scenePlugin', key: 'SpinePlugin', url: 'plugins/3.8.95/SpinePlugin.js', sceneKey: 'spine' }
                ]
            }
        });
    }

  

    preload() {
        
        this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
        
    
    }

    create() {
        WebFont.load({
            custom: {
                families: ['SwisBlack']
            }
        });
      
        this.scene.start(CST.SCENE.NAME_SCENE);
    }

    reset(){
    }
}