export default class AlignGrid {
    constructor(config) {
      this.h = config.height;
      this.w = config.width;
      this.worldShiftX = config.worldShiftX
      this.worldShiftY = config.worldShiftY
      this.rows = config.rows;
      this.cols = config.cols;
      this.scene = config.scene;
  
      this.cw = this.w / this.cols;
  
      this.ch = this.h / this.rows;
    }
    show(a = 1) {
      this.graphics = this.scene.add.graphics().setDepth(9999);
      this.graphics.lineStyle(4, 0xff0000, a);
      //
      //
      //this.graphics.beginPath();
      for (var i = 0; i < this.w; i += this.cw) {
        this.graphics.moveTo(i-this.worldShiftX, 0-this.worldShiftY);
        this.graphics.lineTo(i-this.worldShiftX, this.h-this.worldShiftY);
      }
      for (var i = 0; i < this.h; i += this.ch) {
        this.graphics.moveTo(0-this.worldShiftX, i-this.worldShiftY);
        this.graphics.lineTo(this.w-this.worldShiftX, i-this.worldShiftY);
      }
      this.graphics.strokePath();
    }
    showNumbers(a = 1) {
      this.show(a);
      var n = 0;
      for (var i = 0; i < this.rows; i++) {
        for (var j = 0; j < this.cols; j++) {
          var numText = this.scene.add.text(0, 0, n, {
            color: "red",
            font:"25px Arial"
          }).setDepth(9999);
          numText.setOrigin(0.5, 0.5);
          this.placeAt(j, i, numText);
          n++;
        }
      }
    }
    placeAt(xx, yy, obj) {
      var x2 = this.cw * xx + this.cw / 2 - this.worldShiftX;
      var y2 = this.ch * yy + this.ch / 2 - this.worldShiftY;
      obj.x = x2;
      obj.y = y2;
    }
    placeAtIndex(index, obj, shiftX = 0, shiftY = 0) {
      var yy = Math.floor(index / this.cols);
      var xx = index - yy * this.cols;
      this.placeAt(xx, yy, obj);
      obj.x += shiftX
      obj.y += shiftY 
    }
  }
  
  // var gridConfig = {
  //   scene: this,
  //   cols: 64,
  //   rows: 64,
  //   width: this.map.widthInPixels,
  //   height: this.map.heightInPixels,
  // };
  // this.aGrid = new AlignGrid(gridConfig);
  // this.aGrid.showNumbers();
  