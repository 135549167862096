import { CST } from "../Helper/CST"
import { Extention } from "../Helper/Extension"

class CoinsManager {
   

    static createCoin(scene, coinKey, _x, _y) {
 
         const coin =  scene.add.spine(_x, _y, coinKey,"animation", true)
         coin.setScale(0.1)
         coin.setSkinByName("default")
         coin.setDepth(coin.y)
       
         coin.onCollect = (i) => {
             // window.gameAudio.coin.play();
              scene.sound.play("coin")
              coin.destroy();

              typeof(i) !== "undefined" && scene.coins.splice(i,1);
           
              const x = coin.x - scene.cameras.main.scrollX
              const y = coin.y - scene.cameras.main.scrollY
              const coinDuplicate =  scene.scene.get(CST.SCENE.UI_SCENE).add.spine(x, y, coinKey, "default", true)
              coinDuplicate.setScale(0.1)
              coinDuplicate.setSkinByName("default")
  
             scene.tweens.add({
                 targets:coinDuplicate,
                  y:100,
                  x:100,
                  duration:1000,
                  onComplete:()=>{
                      coinDuplicate.destroy();
                
                      try {
                          Extention.addCoins(scene.scene, 1)
                      } catch{}
                    
                  }
             })
         }

         return coin

    }

    static  createCoins(scene, coinKey, coinsCoords) {
        scene.coins = []
        
        coinsCoords.forEach((coord) => {
            const coin = CoinsManager.createCoin(scene, coinKey, coord[0], coord[1])
            scene.coins.push(coin)
        })
    }

    static update(scene) {
        if(scene.coins) {
            scene.coins.forEach((coin,i) => {
                //console.log(Extention.distance(scene.player.getPlayer().x, scene.player.getPlayer().y,coin.x, coin.y))
                if(Extention.distance(scene.player.getPlayer().x, scene.player.getPlayer().y,
                coin.x, coin.y)
                < 50) {
                
                    coin.onCollect(i)
                    
                }
            })
        }
      
    }
}

export default CoinsManager