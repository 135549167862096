import { CST } from "../Helper/CST";
import { Extention } from "../Helper/Extension";
import LoadingBar from "../Helper/LoadingBar";
import { SpineCharacterData } from "../Scripts/SpineCharacterData";
const SPINE_UI_KEY = "homeSceneSpineUI";

export default class HomeScene extends Phaser.Scene {
  constructor() {
    super({
      key: "home",
      pack: {
        files: [
          {
            type: "scenePlugin",
            key: "SpinePlugin",
            url: "plugins/3.8.95/SpinePlugin.js",
            sceneKey: "spine",
          },
        ],
      },
    });
  }

  preload() {
    new LoadingBar(this);
    for (let prop in CST.COMMON_IMAGES) {
      this.load.image(
        CST.COMMON_IMAGES[prop],
        "./assets/images/Common/" + CST.COMMON_IMAGES[prop]
      );
    }

    for (let prop in CST.CHARACTER_SELECTION_IMAGE) {
      this.load.image(
        CST.CHARACTER_SELECTION_IMAGE[prop],
        "./assets/images/CharacterSelection/" +
          CST.CHARACTER_SELECTION_IMAGE[prop]
      );
    }

    SpineCharacterData.LoadselectedCostumeData();

    this.load.spine(
      CST.BUILDING_SPINE.KEY,
      "./assets/spine/Buildings/Buildings.json",
      ["./assets/spine/Buildings/Buildings.atlas"],
      true
    );

    this.load.spine(
      "hook",
      "./assets/images/Homescreen/Animation/hook.json",
      ["./assets/images/Homescreen/Animation/hook.atlas"],
      true
    );

    this.load.setPath("./assets/images/Homescreen");

    this.load.image("background", "background.png");
    this.load.image("Logo", "Logo.png");

    this.load.setPath("");
    this.load.spine(SPINE_UI_KEY, "./assets/spine/UI/UI.json", [
      "./assets/spine/UI/UI.atlas",
    ]);

    this.load.audio("3", ["./assets/audio/3.mp3"]);
    this.load.audio("Main BG", [
      "./assets/audio/Main BG.ogg",
      "./assets/audio/Main BG.aif",
    ]);
  }

  create() {
    //    this.scene.start(CST.SCENE.NAME_SCENE)
    //    return
    this.Main_BG = this.sound.add("Main BG", { loop: true });
    this.Main_BG.play();

    this.blackOL = this.add
      .image(
        this.game.renderer.width / 2,
        this.game.renderer.height / 2,
        CST.COMMON_IMAGES.BLACK_OVERLAY
      )
      .setScale(50, 50)
      .setDepth(1200)
      .setOrigin(0.5, 0.5);
    Extention.fadeOut(this, this.blackOL, 1500);

    window.gameAudio["3"] = this.sound.add("3");
    this.gw = this.game.renderer.width;
    this.gh = this.game.renderer.height;
    this.add.image(this.gw / 2, this.gh / 2, "background").setScale(0.66);

    let hook = this.add.spine(160, 480, "hook", "animation", true);
    hook.setSkinByName("default");
    hook.setScale(0.66);
    var construct = this.add.spine(
      800,
      400,
      CST.BUILDING_SPINE.KEY,
      CST.BUILDING_SPINE.ANIM.ChineseFoodStall,
      true
    );
    construct.setSkinByName(CST.BUILDING_SPINE.SKIN.Truck);
    this.time.delayedCall(1500, () => {
      const logo = this.add
        .image(this.gw / 2, this.gh / 2, "Logo")
        .setScale(0.66)
        .setAlpha(0);

      let bigButton = this.add.spine(
        0,
        40,
        SPINE_UI_KEY,
        CST.SPINE_UI.ANIMATION.BUTTON_BIG,
        true
      );
      bigButton.setSkin(
        bigButton.skeletonData.findSkin(CST.SPINE_UI.SKIN.BUTTON_BIG)
      );
      bigButton.setSlotsToSetupPose();

      let startButton = this.add
        .container(this.gw / 2, this.gh / 2 + 170, [
          bigButton,
          this.add.text(-80, -40, "START", {
            fontFamily: "SwisBlack",
            fontSize: 60,
            stroke: "#fff",
            color: "#000",
            strokeThickness: 3,
          }),
        ])
        .setSize(210, 100);
      startButton.setInteractive().setAlpha(0);

      startButton.on("pointerup", () => {
        window.gameAudio["3"].play();
        Extention.buttonClick(this, startButton, 40).on("complete", () => {
          startButton.setScale(1);
          this.Main_BG.stop();
          this.scene.start(CST.SCENE.NAME_SCENE);
        });
      });

      this.tweens.add({
        targets: [logo, startButton],
        alpha: 1,
        duration: 1500,
      });
    });
  }

  reset() {}
}
