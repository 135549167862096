import { CST } from "../Helper/CST";
import { Extention } from "../Helper/Extension";
import { GameDataContainer } from "../Helper/GameDataContainer";
import { CheckListData } from "../Scripts/CheckListData";
import { DialogCharacter } from "../Scripts/DialogCharacter";
import { SpineCharacterData } from "../Scripts/SpineCharacterData";
import { EventManager } from "../Utility/EventManager";
const PLAYER_SCALE = 0.85;
const CHARACTER_BOTTOM_OFFSET = -20;
const SAFETY_OFFICER_SCALE = 0.5;
const IMAGES = CST.END;
const SPINE_CHARACTER_KEY = "End"
const SAFETY_OFFICER = "EndSO"
export class EndScene extends Phaser.Scene {
    constructor() {
        super({
            key: CST.SCENE.END_SCENE,
            pack: {
                files: [
                    { type: 'scenePlugin', key: 'SpinePlugin', url: 'plugins/3.8.95/SpinePlugin.js', sceneKey: 'spine' }
                ]
            }
        });
    }

    init(data) {
        this.data = data;
    }

    preload() {
        this.load.scenePlugin({
            key: 'rexuiplugin',
            url: 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexuiplugin.min.js',
            sceneKey: 'rexUI'
        });
        this.load.spine(SPINE_CHARACTER_KEY + CST.GENDER.BOY, "./assets/spine/Boy/Boy.json", ["./assets/spine/Boy/Boy.atlas"], true);
        this.load.spine(SPINE_CHARACTER_KEY + CST.GENDER.GIRL, "./assets/spine/Girl/Girl.json", ["./assets/spine/Girl/Girl.atlas"], true);
        this.load.spine(SAFETY_OFFICER, "./assets/spine/SO/SO.json", ["./assets/spine/SO/SO.atlas"], true);
        this.load.plugin('rexninepatchplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexninepatchplugin.min.js', true);
 
        for (let index in CST.END) {
            this.load.image(CST.END[index], "./assets/images/End/" + CST.END[index]);
        }

        this.load.image("coin", "./assets/images/Profile/coin.png")
        this.load.spine(CST.SPINE_UI.KEY, "./assets/spine/UI/UI.json", ["./assets/spine/UI/UI.atlas"]);
    }

    async create() {
      this.gw = this.game.renderer.width;
      this.gh = this.game.renderer.height;
      this.UI_SCENE = this.scene.get(CST.SCENE.UI_SCENE)
    
      window.gameAudio['cheers'].volume = 0.5
      window.gameAudio['level end'].play()
      window.gameAudio['cheers'].play()

      this.tweens.add({
          targets: window.gameAudio['cheers'],
          volume:0,
          delay:4000,
          duration:2000
      })
     
      this.base = this.add.image(this.gw/2, this.gh/2, IMAGES.BASE).setScale(0)
      await this.animateBase()

      this.congratulations = this.add.image(this.gw/2, this.gh/2 - 205, IMAGES.CONGRATULATIONS).setScale(0)
      await this.animateCongratulations()
      
      for(let i = -1; i < 2; ++i) {
          const star = this.add.image(this.gw/2 + 150 * i,this.gh/2-60, IMAGES.STARS).setScale(0);
          await this.animateStar(star);
      }

      this.player = this.addCharacter(localStorage.getItem(CST.STORAGE_KEY.CHARATER_GENDER), 0, 0);
      this.player.spine.setScale(PLAYER_SCALE, PLAYER_SCALE);
      this.playerContainer = this.add.container(100, this.gh - CHARACTER_BOTTOM_OFFSET, [this.player.spine]);

      this.safetyOfficer = new DialogCharacter(this, 0, 0, SAFETY_OFFICER, SpineCharacterData.boyAnimation().IDLE, true);
      this.safetyOfficer.spine.setScale(-SAFETY_OFFICER_SCALE, SAFETY_OFFICER_SCALE);
      this.soContainer = this.add.container(this.gw - 100, this.game.renderer.height - CHARACTER_BOTTOM_OFFSET, [this.safetyOfficer.spine]);
      
    //   const dur = this.safetyOfficer.playAnimationByName(SpineCharacterData.AnimationName.ThoughtBubbleSmile)
    //   this.time.delayedCall(dur, ()=>{
    //     this.safetyOfficer.playAnimationByName(SpineCharacterData.AnimationName.PoseIntro6, true)
    //   })
      this.safetyOfficer.playAnimationByName(SpineCharacterData.AnimationName.PoseIntro6, true)


      this.coins = this.add.image(this.gw/2 - 40, this.gh/2+ 55, IMAGES.COINS).setAlpha(0);
      this.coinText = this.add.text(this.gw/2 + 50, this.gh/2 + 55, this.data.coinsToAdd, {
        fontFamily: "SwisBlack", fontSize: 40, color: '#FFD700',
      }).setOrigin(1,0.5).setAlpha(0)

       this.fadeInCoinAndText();

       

       const COINS_QUANTITY = 8;
       for(let i = 0 ; i < COINS_QUANTITY; ++i) {
            const coin = this.UI_SCENE.add.image(this.coins.x, this.coins.y, "coin").setDepth(9999).setVisible(false)

            this.time.delayedCall((i*150), ()=>{
                this.sound.play("coin")
                this.animateCoin(coin)
                coin.setVisible(true)
            })
       }

       this.time.delayedCall(1000,()=>{
        this.time.addEvent({
            callback:()=>{
                Extention.addCoins(this.scene, 1);
            },
            repeat:this.data.coinsToAdd-1,
            delay:(COINS_QUANTITY * 150)/this.data.coinsToAdd
        })
    
       })

     
       this.time.delayedCall(2500, ()=>{
        
       
           this.time.delayedCall(500, ()=>{
            this.UI_SCENE.fullScreenAndProfileInOut(false)
           })

           this.time.delayedCall(500, ()=>{

            if(this.data.startAgainButton) {
                this.addReplayButton()
            } else {
                this.addNextButton()
            }
          
           
           // this.addReplayButton()
            this.nextButton.setAlpha(0)
            //this.replayButton.setAlpha(0)
    
            this.animateButtons()
           })
       })

       Extention.showGameHUD(this.scene, {slideTopUI:true,show:false})
       this.scene.bringToTop(CST.SCENE.UI_SCENE)
       // this.addReplayButton()
  
 
            
    }

    addCharacter(gender, x, y) {
        var character = new DialogCharacter(this, x, y, SPINE_CHARACTER_KEY + gender, SpineCharacterData.boyAnimation().IDLE, true);
        var costumeData = SpineCharacterData.getDefaultCostume(gender);
        character.applyDefaultSkin(costumeData);
        character.addDefaultPPE(SpineCharacterData.getDefaultPPEKit());
        character.applySkins(SpineCharacterData.getCurrentCostumeWithoutPPE());
        character.playAnimationByName(SpineCharacterData.AnimationName.PoseIntro2, true)
        return character;
    }
    animateButtons() {
        this.tweens.add({
            //targets:[this.nextButton, this.replayButton],
            targets:[this.nextButton],
            alpha:1,
            duration:500,
        })
    }
    addNextButton() {
        let x = this.gw/2
        if(this.replayButton) {
            x = this.gw/2 + 120
        }
        let bigButton = this.add.spine(0, 40, CST.SPINE_UI.KEY, CST.SPINE_UI.ANIMATION.BUTTON_BIG, true);
        bigButton.setSkin(bigButton.skeletonData.findSkin(CST.SPINE_UI.SKIN.BUTTON_BIG));
        bigButton.setSlotsToSetupPose();
 
        let nextIcon = this.add.image(-50, 0, IMAGES.NEXT_ICON).setScale(0.6)
        let nextButtonText = this.add.text(-20, -30, "NEXT",
        { fontFamily: "SwisBlack", fontSize: 45, stroke: '#fff', color: '#000', strokeThickness: 3 });
 
         let nextButton = this.add.container(x, this.gh/2 + 150,
             [bigButton, nextIcon, nextButtonText]).setSize(210, 100);
         nextButton.setInteractive();
         nextButton.setScale(0.8)
      //   nextButton.setAlpha(0);
       //  nextButton.setDepth(3)
   
         nextButton.on("pointerup", () => {
            window.gameAudio["3"].play()
            if(this.data.sceneToStart) {
                Extention.startScene(this.scene.get(GameDataContainer.CurrentMapScene).scene, this.data.sceneToStart);
            } else {
                this.data.callback()
            }
           
            Extention.stopScene(this.scene, CST.SCENE.END_SCENE)
         })
 
         this.nextButton = nextButton;
    }
    addReplayButton() {

        let bigButton = this.add.spine(0, 40, CST.SPINE_UI.KEY, CST.SPINE_UI.ANIMATION.BUTTON_BIG, true);
        bigButton.setSkin(bigButton.skeletonData.findSkin(CST.SPINE_UI.SKIN.BUTTON_BIG));
        bigButton.setSlotsToSetupPose();
   
        let nextButtonText = this.add.text(0, 0, "HOME",
        { fontFamily: "SwisBlack", fontSize: 50, stroke: '#fff', color: '#000', strokeThickness: 3 }).setOrigin(0.5);
 
         let nextButton = this.add.container(this.gw/2, this.gh/2 + 150,
             [bigButton,  nextButtonText]).setSize(210, 100);
         nextButton.setInteractive();
         nextButton.setScale(0.8)
      //   nextButton.setAlpha(0);
       //  nextButton.setDepth(3)
   
         nextButton.on("pointerup", () => {
            
         })
 
         this.replayButton = nextButton;
    }
    animateCoin(coin) {
        this.tweens.add({
            targets:coin,
            y:"+=15",
            x:"+=15",
            duration:200,
            onComplete:()=> {
                this.tweens.add({
                    targets:coin,
                    y:65,
                    x:75,
                    duration:1000,
                    onComplete:()=>{
                        this.tweens.add({
                            targets: coin,
                            y:80,
                            x:90,
                            duration:200,
                            onComplete:()=>{
                                coin.destroy()
                               
                            }
                        })
                    }
                })
               
            },
   
        })
    }

    fadeInCoinAndText() {
        return new Promise(resolve=>{
            this.tweens.add({
                targets:[this.coins, this.coinText],
                alpha:1,
                duration:1000,
                onComplete:resolve
            })
        })
    }
    animateStar(star) {
        return new Promise(resolve=>{
            this.tweens.add({
                targets:star,
                scale:1,
                duration:200,
                onComplete:resolve
            })
        })
      
    }

    animateBase() {
        return new Promise(resolve=>{
            this.tweens.add({
                targets:this.base,
                scale:1.2,
                duration:400,
                onComplete:()=>{
                    this.tweens.add({
                        targets:this.base,
                        scale:1,
                        duration:200,
                        onComplete:resolve
                    })
                }
            })
        })
    }

    animateCongratulations() {
        return new Promise(resolve=>{
            this.tweens.add({
                targets:this.congratulations,
                scale:1,
                duration:300,
                onComplete:resolve
            })
        })  
    }

    update(){
      
    }

    reset() {
        
    }
  
}
