export default class LoadingBar {
  constructor(scene) {
    scene.load.image("Base", "./assets/images/Loading Screen/Base.png");
    scene.load.image("Fill", "./assets/images/Loading Screen/Fill.png");

    var width = scene.cameras.main.width;
    var height = scene.cameras.main.height;
    let energyContainer = scene.add.sprite(width / 2, height / 2 - 100, "Base");
    let energyBar = scene.add.sprite(
      energyContainer.x,
      energyContainer.y,
      "Fill"
    );
    this.energyBar = energyBar;

    this.energyMask = scene.add.sprite(energyBar.x, energyBar.y, "Fill");

    this.energyMask.visible = false;
    energyBar.mask = new Phaser.Display.Masks.BitmapMask(
      scene,
      this.energyMask
    );

    var loadingText = scene.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: "Loading...",
      style: {
        font: "20px monospace",
        fill: "#ffffff",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = scene.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#ffffff",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = scene.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#ffffff",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    scene.load.on("progress", (value) => {
      percentText.active && percentText.setText(parseInt(value * 100) + "%");

      if (this.energyMask)
        this.energyMask.x =
          this.energyBar.x -
          this.energyBar.displayWidth +
          this.energyBar.displayWidth * value;
    });

    scene.load.on("filecomplete-image-Fill", () => {
      let energyContainer = scene.add.sprite(
        width / 2,
        height / 2 - 100,
        "Base"
      );
      let energyBar = scene.add.sprite(
        energyContainer.x,
        energyContainer.y,
        "Fill"
      );
      this.energyBar = energyBar;

      this.energyMask = scene.add.sprite(energyBar.x, energyBar.y, "Fill");

      this.energyMask.visible = false;
      energyBar.mask = new Phaser.Display.Masks.BitmapMask(
        scene,
        this.energyMask
      );
    });
    scene.load.on("fileprogress", function (file) {
      try {
        assetText.setText("Loading asset: " + file.key);
      } catch {}
    });
    scene.load.on("complete", function () {
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
  }
}
