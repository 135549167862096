export default {
    types: ["CEMENT", "FOUNDATION"],
    addObjectsToActiveScene:true, // takeActiveSceneWordInput:true
    cementConfig: [{
        startX:500, 
        startY:400, 
        config:[
              []
            ]
    }]

    // ladderConfig: [{
    //     startX:30,
    //     startY:130,
    //     config:[[1,1]]
    // }, {
    //     startX:280,
    //     startY:170,
    //     config:[[1,1,1]]
    // },
    // {
    //     startX:500,
    //     startY:100,
    //     config:[[1,0,1,1,0,0],
    //             [0,0,0,0,1,0],
    //             [0,0,0,0,0,1]]
    // },{
    //     startX:830,
    //     startY:190,
    //     config:[[1,1,1,1,1,1,1,0,0],
    //             [0,0,0,0,0,0,0,1,1]]
    // }],



    // groundConfig: [
    //     {
    //         startX:30,
    //         startY:130,
    //         config:[[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    //                [1,1,1,1,0,0,0,0,0,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    //                [1,1,1,1,1,1,1,1,1,0,0,1,1,1,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0],
    //                [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0],
    //                [1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,1],
    //                [1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0],
    //                [1,1,1,1,1,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0],
    //                [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0],
    //                [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0],
    //                [0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0],
    //                [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0]]
    //     }
    // ]    
}