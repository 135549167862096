export default {
    types: ["STONE"],
    stoneConfig: [{
        startX:20, 
        startY:20, 
        config:[
               [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1],
               [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,2,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,2,2,2,2,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,2,2,2,2,1,1,1,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,2,2,2,2,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,2,2,2,2,1,1,0,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,2,2,2,2,0,0,0,0,0],
               [0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,0,0,0],
               [0,0,0,0,0,0,0,0,2,2,2,2,2,1,1,1,1,1,1,1,0,0,0,0],
               [0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0],
            
            ]
    }]

    // ladderConfig: [{
    //     startX:30,
    //     startY:130,
    //     config:[[1,1]]
    // }, {
    //     startX:280,
    //     startY:170,
    //     config:[[1,1,1]]
    // },
    // {
    //     startX:500,
    //     startY:100,
    //     config:[[1,0,1,1,0,0],
    //             [0,0,0,0,1,0],
    //             [0,0,0,0,0,1]]
    // },{
    //     startX:830,
    //     startY:190,
    //     config:[[1,1,1,1,1,1,1,0,0],
    //             [0,0,0,0,0,0,0,1,1]]
    // }],



    // groundConfig: [
    //     {
    //         startX:30,
    //         startY:130,
    //         config:[[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    //                [1,1,1,1,0,0,0,0,0,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    //                [1,1,1,1,1,1,1,1,1,0,0,1,1,1,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0],
    //                [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0],
    //                [1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1],
    //                [1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,1],
    //                [1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0],
    //                [1,1,1,1,1,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0],
    //                [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0],
    //                [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0],
    //                [0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0],
    //                [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0]]
    //     }
    // ]    
}