/** @type {import ("../../typing/phaser")} */
/** @type {import ("../../typing/SpinePlugin")} */
/** @type {import ("../../typing/spine")} */

import { CST } from "../Helper/CST";
import { Character } from "../Scripts/Charater";
import { SpineCharacterData } from "../Scripts/SpineCharacterData";
import { JoyStickController } from "../Utility/JoyStickController";
import { InputManager } from "../Utility/InputManager";
import { Extention } from "../Helper/Extension";
import { EventManager } from "../Utility/EventManager";
import { CharacterDialogData } from "../Scripts/CharacterDialogData";
import { UIScene } from "./UIScene";
import { GameDataContainer } from "../Helper/GameDataContainer";
import { CheckListData } from "../Scripts/CheckListData";
import Drone from "../Scripts/Drone";
import Excavator from "../Scripts/Excavator";
import buildArea from "../buildAreas/whitelining";
import LoadingBar from "../Helper/LoadingBar";
const isDevelopment = false;
// dialoges, isDevelopment, delays, .every(), tilesToBeMarked, tilesDiggedWithExcavator, REMOVE IT

const SPINE_KEY = "LEVEL1_WHITELINING";
const SAFETY_OFFICER = "WhiteliningSafetyOfficer";
const LOCATOR = "Locator";
const EXCAVATOR = "Excavator";
const PLAYER_SPEED = 5;
const POSITION_MULTIPLYER = 0.66;

const PLAYER_INIT_GO_SO_DURATION = 2000;
const DELAY_BEFORE_PLAYER_GO_SO = 1500;
const PLAYER_GO_CENTER_DURATION = 3000;
const CLOSE_MOBILE_POPUP_DELAY = 4000;
const WHITELINS_ZOOM_SCROLL_DURATION = 3000;
const DAY_NIGHT_DURATION = 3000;
const DELAY_BEFORE_NIGHT = 4000;

const WHITELINES_ZOOM = 1.35;

const SCENE_KEY = CST.SCENE.LEVEL1_WHITELINING_SCENE;
const IMAGES = CST.LEVEL1_WHITELINING;
const IMAGES_V2 = CST.LEVEL1_WHITELINING_V2;
const IMAGES_V3 = CST.LEVEL1_WHITELINING_V3;
const KANSAS = CST.KANSAS_APP;
const PIPES = CST.PIPES;
const PLANTS_SPINE = SPINE_KEY + "_PLANTS";
const COIN = "Level1WhiteliningCoin";
const skatepark = [
  "BASE",
  "MAIN_AREA",
  "MIDDLE_U",
  "SIDE_S",
  "WALL_UP",
  "WALL_DOWN",
  "GARDEN",
  "DECORATIONS",
  "BUILDING",
];
export class Level1WhiteliningScene extends Phaser.Scene {
  constructor() {
    super({
      key: SCENE_KEY,
      pack: {
        files: [
          {
            type: "scenePlugin",
            key: "SpinePlugin",
            url: "plugins/3.8.95/SpinePlugin.js",
            sceneKey: "spine",
          },
        ],
      },
    });
  }

  preload() {
    new LoadingBar(this);
    localStorage.setItem("buildModeUnlocked", JSON.stringify(true));
    Extention.stopScene(this.scene, CST.SCENE.LEVEL1_EXCAVATION_SCENE);
    for (let i in CST.PROFILE) {
      this.load.image(
        CST.PROFILE[i],
        "./assets/images/Profile/" + CST.PROFILE[i]
      );
    }
    this.load.image("coin", "./assets/images/Profile/coin.png");
    skatepark.forEach((img) => {
      const name = img.toLowerCase();

      this.load.atlas(
        name + ".png",
        "./assets/images/Skatepark/" + name + "/" + name + ".png",
        "./assets/images/Skatepark/" + name + "/" + name + ".json"
      );
    });
    for (let i in CST.SKATEPARK_BUILD_SCENE_ICONS) {
      this.load.image(
        CST.SKATEPARK_BUILD_SCENE_ICONS[i],
        "./assets/images/Skatepark/Icons/" + CST.SKATEPARK_BUILD_SCENE_ICONS[i]
      );
    }
    for (let index in IMAGES_V2) {
      console.log("Level2 preload : " + index + " : " + IMAGES_V2[index]);
      this.load.image(
        IMAGES_V2[index],
        "./assets/images/Level1/WhiteliningLevel2/" + IMAGES_V2[index]
      );
    }
    for (let index in IMAGES_V3) {
      console.log("Level2 preload : " + index + " : " + IMAGES_V3[index]);
      this.load.image(
        IMAGES_V3[index],
        "./assets/images/Level1/WhiteliningLevel3/" + IMAGES_V3[index]
      );
    }
    ///////////////////////
    for (let index in KANSAS) {
      console.log("Level2 preload : " + index + " : " + KANSAS[index]);
      this.load.image(
        KANSAS[index],
        "./assets/images/Level1/WhiteliningLevel2/Kansas App UI/" +
          KANSAS[index]
      );
    }
    for (let index in PIPES) {
      console.log("Level2 preload : " + index + " : " + PIPES[index]);
      this.load.image(
        PIPES[index],
        "./assets/images/Level1/WhiteliningLevel2/pipes/" + PIPES[index]
      );
    }
    for (let i in CST.BUILD) {
      this.load.image(
        CST.BUILD[i],
        "./assets/images/BuildMode/" + CST.BUILD[i]
      );
    }

    for (let index in IMAGES) {
      console.log("Level1 preload : " + index + " : " + IMAGES[index]);
      this.load.image(
        IMAGES[index],
        "./assets/images/Level1/Whitelining/" + IMAGES[index]
      );
    }
    this.load.image(
      "shadow",
      "./assets/images/Common/" + CST.COMMON_IMAGES.BLACK_OVERLAY
    );
    /////////////////////////////////

    for (let i = 1; i < 61; ++i) {
      this.load.image(
        `Whitelining-Gameplay_${i}`,
        "./assets/images/Level1/Whitelining/whitelines/" +
          `Whitelining-Gameplay_${i}.png`
      );
      this.load.image(
        `Whitelining-Gameplay_${i}-Empty`,
        "./assets/images/Level1/Whitelining/whitelines/" +
          `Whitelining-Gameplay_${i}-Empty.png`
      );
    }
    this.load.spine(
      COIN,
      "./assets/spine/Coin/coin-pro.json",
      ["./assets/spine/Coin/coin-pro.atlas"],
      true
    );
    this.load.spine(
      PLANTS_SPINE,
      "./assets/spine/Plants/Plants.json",
      ["./assets/spine/Plants/Plants.atlas"],
      true
    );
    this.load.json(
      "whitelining_level_1",
      "./assets/colliders/whitelining_level_1.json"
    );

    // this.load.spine(IMAGES.BRICK_SPINE.KEY_MACHINE, "./assets/spine/Level1/boot_puzzle_dungeon/Hat Machine.json", ["./assets/spine/Level1/boot_puzzle_dungeon/Hat Machine.atlas"]);
    // this.load.spine(IMAGES.BRICK_SPINE.KEY_HAT, "./assets/spine/Level1/boot_puzzle_dungeon/Hat_NPC.json", ["./assets/spine/Level1/boot_puzzle_dungeon/Hat_NPC.atlas"]);

    this.load.spine(SPINE_KEY + CST.GENDER.BOY, "./assets/spine/Boy/Boy.json", [
      "./assets/spine/Boy/Boy.atlas",
    ]);
    this.load.spine(
      SPINE_KEY + CST.GENDER.GIRL,
      "./assets/spine/Girl/Girl.json",
      ["./assets/spine/Girl/Girl.atlas"]
    );
    this.load.spine(
      SAFETY_OFFICER,
      "./assets/spine/SO/SO.json",
      ["./assets/spine/SO/SO.atlas"],
      true
    );

    this.load.spine(
      LOCATOR,
      "./assets/spine/Locator/Locator.json",
      ["./assets/spine/Locator/Locator.atlas"],
      true
    );

    this.load.spine(
      SPINE_KEY + CST.THOUGHT_BUBBLE_SPINE.KEY,
      "./assets/spine/ThoughtBubble/Thought-Bubbles.json",
      ["./assets/spine/ThoughtBubble/Thought-Bubbles.atlas"]
    );
    this.thoughtBubbleSpineKey = SPINE_KEY + CST.THOUGHT_BUBBLE_SPINE.KEY;
    this.thoughtBubbleSkin = CST.THOUGHT_BUBBLE_SPINE.SKIN.SHOVEL;

    this.load.spine(
      CST.LEVEL1_WHITELINING_SPINE.KEY,
      "./assets/spine/Excavator/Excavator.json",
      ["./assets/spine/Excavator/Excavator.atlas"],
      true
    );
    this.load.spine(
      CST.LEVEL1_WHITELINING_SPINE.KEY2,
      "./assets/spine/Bulldozer/Bulldozer.json",
      ["./assets/spine/Bulldozer/Bulldozer.atlas"],
      true
    );
    this.load.spine(
      CST.LEVEL1_WHITELINING_SPINE.KEY3,
      "./assets/spine/Soil/soil.json",
      ["./assets/spine/Soil/soil.atlas"],
      true
    );

    this.load.audio("1 night fall", [
      "./assets/audio/Whiteline Level/1 night fall.mp3",
    ]);
    this.load.audio("2 day", ["./assets/audio/Whiteline Level/2 day.mp3"]);
    this.load.audio("3 logo", ["./assets/audio/Whiteline Level/3 logo.mp3"]);
    this.load.audio("4 dot", ["./assets/audio/Whiteline Level/4 dot.mp3"]);
    this.load.audio("5 ok", ["./assets/audio/Whiteline Level/5 ok.mp3"]);
    this.load.audio("11 raise", [
      "./assets/audio/Whiteline Level/11 raise.mp3",
    ]);
    this.load.audio("12 rotation", [
      "./assets/audio/Whiteline Level/12 rotation.mp3",
    ]);
    this.load.audio("13 correct rotation", [
      "./assets/audio/Whiteline Level/13 correct rotation.mp3",
    ]);

    this.load.spine(
      "water",
      "./assets/damageScenarios/water-line-damage/skeleton.json",
      ["./assets/damageScenarios/water-line-damage/skeleton.atlas"],
      true
    );
    this.load.spine(
      "power",
      "./assets/damageScenarios/power-line-damage/skeleton.json",
      ["./assets/damageScenarios/power-line-damage/skeleton.atlas"],
      true
    );
    this.load.image(
      "electricSign",
      "./assets/damageScenarios/power-line-damage/electricSign.png"
    );
    this.load.spine(
      "sewer",
      "./assets/damageScenarios/sewer-line-damage/skeleton.json",
      ["./assets/damageScenarios/sewer-line-damage/skeleton.atlas"],
      true
    );
    this.load.spine(
      "gas",
      "./assets/damageScenarios/gas-line-damage/skeleton.json",
      ["./assets/damageScenarios/gas-line-damage/skeleton.atlas"],
      true
    );

    this.load.image(
      "wifi",
      "./assets/damageScenarios/communication-line-damage/Wifi.png"
    );
    this.load.image(
      "redIcon",
      "./assets/damageScenarios/communication-line-damage/cross.png"
    );
    this.load.audio("incorrect", [
      "./assets/damageScenarios/communication-line-damage/incorrect.mp3",
    ]);

    for (let i = 0; i < 7; i++) {
      this.load.audio("Building-" + i, [
        "./assets/audio/buildings/Building-" + i + ".mp3",
      ]);
    }
  }

  create() {
    this.nightFallSound = this.sound.add("1 night fall");
    this.daySound = this.sound.add("2 day");
    this.logoSound = this.sound.add("3 logo");
    this.dotSound = this.sound.add("4 dot");
    this.okSound = this.sound.add("5 ok");
    this.raiseSound = this.sound.add("11 raise");
    this.rotationSound = this.sound.add("12 rotation");
    this.correctRotation = this.sound.add("13 correct rotation");

    for (let i = 0; i < 7; i++) {
      this["Building-" + i] = this.sound.add(["Building-" + i]);
    }

    //setTimeout(()=>this.twoDaysDialogCompleted(), 1500)
    this.scene.stop(this.scene.get(CST.SCENE.UI_SCENE));
    this.whitelinesZoomScale = 2 - WHITELINES_ZOOM; // 2 - 1.35 = 0.65
    this.whitelines = [];

    this.gender = localStorage.getItem(CST.STORAGE_KEY.CHARATER_GENDER);
    this.eventManager = EventManager.getInstance(true);
    this.playerHasPassedRightSide = false;
    GameDataContainer.CurrentMapScene = CST.SCENE.LEVEL1_WHITELINING_SCENE;

    //this.setupWorld();
    this.addFloor();
    this.cameras.main.setBounds(0, 0, 1925, 720);
    this.matter.world.setBounds(0, 0, 1925, 720);
    this.matter.world.setGravity(0, 0, 0);

    this.setupSafetyOfficer();
    this.setUpPlayer();
    //  this.cameras.main.startFollow(this.player.playerContainer, false, 0.1, 0.1)
    // this.setController()
    this.incorrect = this.sound.add("incorrect");
    this.time.delayedCall(DELAY_BEFORE_PLAYER_GO_SO, () => {
      // this.useLocatorDialogCompleted()
      // return

      Extention.doMove(
        this,
        this.player.getPlayer(),
        260,
        480,
        PLAYER_INIT_GO_SO_DURATION
      );
      this.player.playAnimation(SpineCharacterData.AnimationState.Walking);

      this.time.delayedCall(PLAYER_INIT_GO_SO_DURATION, () => {
        //return;
        this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
        this.currentDialogue = "saferWorkingHere";
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetSaferWorkingHereData()
        );
      });
    });

    ///////////////////////////

    this.addOwnDepthImage(250, 216, IMAGES["1"]).setScale(0.7);
    this.addOwnDepthImage(144, 336, IMAGES["2"]).setScale(0.7);
    this.addOwnDepthImage(431, 304, IMAGES["3"]).setScale(0.7);
    this.addOwnDepthImage(454, 175, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(493, 204, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(437, 359, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(317, 347, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(224, 302, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(217, 630, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(313, 672, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(570, 635, IMAGES["7"]).setScale(0.7);
    this.addOwnDepthImage(588, 694, IMAGES["10"]);
    this.addOwnDepthImage(588, 694, IMAGES["10"]);
    this.addOwnDepthImage(574, 190, IMAGES.DUSTBIN).setScale(0.6);
    const grass = this.addOwnDepthImage(
      1721,
      116,
      IMAGES.UPPER_RIGHT_GRASS
    ).setDepth(2);
    this.addOwnDepthImage(1728, 25, IMAGES.HOUSE_UPPER).setDepth(
      grass.depth + 1
    );
    this.addOwnDepthImage(227, 680, IMAGES.LADDER);
    this.addOwnDepthImage(383, 170, IMAGES.SIGN).setScale(0.6);
    this.addOwnDepthImage(378, 230, IMAGES.PIT);
    this.addOwnDepthImage(540, 326, IMAGES.SEPARATOR);
    const electricity = this.addOwnDepthImage(
      844,
      123,
      IMAGES.ELECTRICITY_POLES
    );
    electricity.setDepth(3);
    this.addOwnDepthImage(1852, 452, IMAGES.ELECTRICITY);
    const house = this.addOwnDepthImage(1123, 94, IMAGES.HOUSE2).setDepth(2);
    this.addOwnDepthImage(1388, 98, IMAGES.HOUSE3).setDepth(2);
    this.addOwnDepthImage(410, 8, IMAGES.HOUSE_BOTTOM_2);
    this.addOwnDepthImage(96, 8, IMAGES.HOUSE_BOTTOM);
    this.addOwnDepthImage(497, 93, IMAGES.HOUSE).setDepth(2);
    this.addOwnDepthImage(1126, 93, IMAGES.RAILING).setDepth(house.depth);

    this.addOwnDepthImage(128, 678, IMAGES.WOODEN_PLANK);
    this.addOwnDepthImage(270, 275, IMAGES.WOODEN_PLANK).setFlipX(true);
    this.addOwnDepthImage(1728, 170, IMAGES.WATER_FOUNTAIN).setScale(0.4);

    this.addOwnDepthSpine(
      1605,
      120,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_PetStore
    )
      .setScale(0.7)
      .setDepth(400);
    this.addOwnDepthSpine(
      1660,
      120,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_PetStore
    )
      .setScale(0.7)
      .setDepth(400);
    this.addOwnDepthSpine(
      1720,
      120,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_PetStore
    )
      .setScale(0.7)
      .setDepth(400);
    this.addOwnDepthSpine(
      1760,
      120,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_PetStore
    )
      .setScale(0.7)
      .setDepth(400);
    this.addOwnDepthSpine(
      1830,
      120,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_PetStore
    )
      .setScale(0.7)
      .setDepth(400);
    this.addOwnDepthSpine(
      1860,
      120,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_PetStore
    )
      .setScale(0.7)
      .setDepth(400);

    this.addOwnDepthSpine(
      1545,
      150,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Back_Tree_1
    )
      .setScale(0.4)
      .setDepth(200);
    this.addOwnDepthSpine(
      1545,
      225,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Back_Tree_1
    )
      .setScale(0.4)
      .setDepth(200);
    this.addOwnDepthSpine(
      1900,
      150,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Back_Tree_1
    )
      .setScale(0.4)
      .setDepth(200);
    this.addOwnDepthSpine(
      1900,
      225,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Back_Tree_1
    )
      .setScale(0.4)
      .setDepth(200);

    this.addOwnDepthSpine(
      1820,
      200,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Long_Leaves_Pot
    )
      .setScale(0.7)
      .setDepth(201);
    this.addOwnDepthSpine(
      1645,
      150,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Long_Leaves_Pot
    )
      .setScale(0.7)
      .setDepth(201);
    this.addOwnDepthSpine(
      1665,
      222,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Leaves_Pot
    )
      .setScale(0.5)
      .setDepth(201);
    this.addOwnDepthSpine(
      1780,
      224,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Leaves_Pot
    )
      .setScale(0.5)
      .setDepth(201);
    this.addOwnDepthSpine(
      1637,
      195,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_Pink_Flower
    )
      .setScale(0.5)
      .setDepth(201);
    this.addOwnDepthSpine(
      1725,
      228,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_Pink_Flower
    )
      .setScale(0.5)
      .setDepth(201);
    this.addOwnDepthSpine(
      1822,
      165,
      PLANTS_SPINE,
      CST.PLANT_SPINE.ANIM.Plants,
      true,
      CST.PLANT_SPINE.SKIN.Bush_Pink_Flower
    )
      .setScale(0.5)
      .setDepth(201);

    this.addOwnDepthImage(1575, 135, IMAGES.STREET_LIGHT)
      .setOrigin(0.5, 1)
      .setScale(0.6);
    this.addOwnDepthImage(1685, 135, IMAGES.STREET_LIGHT)
      .setOrigin(0.5, 1)
      .setScale(0.6);
    this.addOwnDepthImage(1800, 135, IMAGES.STREET_LIGHT)
      .setOrigin(0.5, 1)
      .setScale(0.6);
    this.addOwnDepthImage(1877, 135, IMAGES.STREET_LIGHT)
      .setOrigin(0.5, 1)
      .setScale(0.6);
    this.blackOL = this.add
      .image(
        this.game.renderer.width / 2,
        this.game.renderer.height / 2,
        CST.COMMON_IMAGES.BLACK_OVERLAY
      )
      .setScale(50, 50)
      .setDepth(1200)
      .setOrigin(0.5, 0.5);
    Extention.fadeOut(this, this.blackOL, 1500);

    skatepark.forEach((img) => {
      const name = img.toLowerCase();

      const config = {
        key: name + ".png",
        frames: name + ".png",
        frameRate: 15,
      };

      this.anims.create(config);
    });

    this.locatorDialogAmount = 0;
    this.whiteline = this.addOwnDepthImage(1125, 520, IMAGES.WHITELINE)
      .setAlpha(0)
      .setDepth(10)
      .setScale(0.9);

    this.fadeIn();

    this.setWhitelinesInteractive();

    this.scene.bringToTop(CST.SCENE.UI_SCENE);
    this.UIScene = this.scene.get(CST.SCENE.UI_SCENE);

    this.addColliders(
      this.cache.json.get("whitelining_level_1")["00 REF-GAmeplay"]
    );
    //////////////////////////////////////////////'

    this.colors = ["blue", "green", "orange", "red", "yellow"];

    this.flags = this.getFlagsConfig();
    this.areaToDigWithExcavator = this.getAreaToDigWithExcavatorConfig();

    this.areaToPlaceCement = this.getAreaToPlaceCement();
    this.areaToPlaceCementPositions = this.getAreaToPlaceCementPositions();
    this.areaToPlaceFoundations = this.getAreaToPlaceFoundations();
    this.areaToPlaceFoundationsPositions =
      this.getAreaToPlaceFoundationsPositions();

    this.cementsToBePlaced = this.areaToPlaceCementPositions.length;
    this.foundationsToBePlaced = this.areaToPlaceFoundationsPositions.length;
    this.tilesToBeMarked = this.flags
      .flatMap((c) => c)
      .filter((c) => c !== "n").length;

    this.cementsPutAmount = 0;
    this.fundationPutAmount = 0;
    this.markedTiles = 0;
    this.TILES_DIGGED_WITH_EXCAVATOR = 0;
    this.tilesDiggedWithExcavator = 0;

    this.excavatorDiggableAreas = [];
    this.shovelDiggableAreas = [];
    this.placableAreas = [];
    this.placedFlags = [];
    // console.log("tiles needed to be marked with an excevator: ",this.tilesToDigWithExcavator)
    //console.log("tiles to be marked with a shovel: ",this.tilesToBeMarked)
    this.buildings = 0;

    this.input.on("pointermove", () => {
      this.inputX = this.input.activePointer.worldX;
      this.inputY = this.input.activePointer.worldY;
    });

    this.addDialoguesHandler();
    // this.createKey()
    this.input.keyboard.on("keydown-NUMPAD_ONE", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_OFFICE_SCENE);
    });
    this.input.keyboard.on("keydown-NUMPAD_TWO", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_DUNGEON_SCENE);
    });
    this.input.keyboard.on("keydown-NUMPAD_THREE", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_DUNGEON_GEM_SCENE);
    });
    this.input.keyboard.on("keydown-NUMPAD_FOUR", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_DUNGEON_POND_SCENE);
    });

    this.input.keyboard.on("keydown-NUMPAD_FIVE", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_DUNGEON_RIVER_SCENE);
    });

    this.input.keyboard.on("keydown-NUMPAD_SIX", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(
        this.scene,
        CST.SCENE.LEVEL1_DUNGEON_HAT_PUZZLE_SCENE
      );
    });
    this.input.keyboard.on("keydown-NUMPAD_SEVEN", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(
        this.scene,
        CST.SCENE.LEVEL1_DUNGEON_BOOT_PUZZLE_SCENE
      );
    });
    this.input.keyboard.on("keydown-NUMPAD_EIGHT", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_EXCAVATION_SCENE);
    });
    this.input.keyboard.on("keydown-NUMPAD_NINE", () => {
      Extention.stopScene(this.scene, GameDataContainer.CurrentMapScene);
      Extention.startScene(this.scene, CST.SCENE.LEVEL1_WHITELINING_SCENE);
    });

    //this.addProfileView();
  }
  waterPipeDialogCompleted() {
    Extention.fadeIn(this, this.blackOL, 1500);

    this.time.delayedCall(1500, () => {
      Extention.fadeOut(this, this.blackOL, 1500);

      this.pipeDamagedCB && this.pipeDamagedCB();

      this.placableAreas.forEach((tile) => {
        if (tile.isDiggedWithExcavator) {
          tile.isDigged = false;
          tile.isDiggedWithExcavator = false;
          tile.dirt.destroy();
          tile.dirt = null;
        }
      });

      this.tilesDiggedWithExcavator = this.TILES_DIGGED_WITH_EXCAVATOR; // 0
      this.excavatorDiggableAreas.forEach((area) => area.destroy());
      this.excavatorDiggableAreas = [];
      this.showExcavatorDiggableAreas();
      this.digUntouchableTiles();
      this.isPipeDamaged = false;
      this.excavator.excavatorContainer.x =
        this.whiteline.x + this.whiteline.displayWidth / 2 - 100;
      this.excavator.excavatorContainer.y = this.whiteline.y;
    });
  }

  showNoWifi() {
    const SCALE = this.whitelinesZoomScale;

    const elements = [];
    const popup = this.add
      .image(this.whiteline.x, this.whiteline.y - 50, KANSAS.POP_UP_MOBILE)
      .setScale(0)
      .setDepth(1000);
    const whiteBG = this.add
      .image(popup.x, popup.y, KANSAS.WHITE_BG)
      .setDepth(999)
      .setScale(0);
    const bg = this.add
      .image(popup.x, popup.y, KANSAS.GREEN_BG)
      .setDisplaySize(popup.displayWidth - 20, popup.displayHeight - 20)
      .setDepth(999)
      .setScale(0);

    this.tweens.add({
      targets: [popup, whiteBG, bg],
      scale: 1 * SCALE,

      onComplete: () => {
        // this.logoSound.play();

        whiteBG.destroy();

        const wifi = this.add
          .image(popup.x, popup.y, "wifi")
          .setAlpha(0)
          .setDepth(1000);

        this.tweens.add({
          targets: wifi,
          duration: 1000,
          alpha: 1,
          onComplete: () => {
            this.incorrect.play();
            const redIcon = this.add
              .image(wifi.x, wifi.y, "redIcon")
              .setDepth(1001);

            this.tweens.add({
              targets: redIcon,
              alpha: 0,
              duration: 0,
              hold: 1000,
              delay: 1000,
              repeatDelay: 1000,
              repeat: 2,
              yoyo: true,
              onComplete: () => {
                this.currentDialogue = "waterPipe";
                Extention.showDialogBox(
                  this.scene,
                  CharacterDialogData.GetWaterPipeDamageData()
                );

                elements.push(popup, whiteBG, bg, wifi, redIcon);
                elements.forEach((element) => element && element.destroy());
              },
            });
          },
        });
      },
    });
  }

  update() {
    if (!this.secondBuildMode) this.player.update();
    if (!this.secondBuildMode)
      this.safetyOfficer.setDepth(this.safetyOfficer.getPlayer().y);
    if (this.setPlayerFollowingExcavator) {
      this.safetyOfficer.setDepth(this.player.getPlayer().y - 1);
      const tilesToDig = [];
      tilesToDig[0] = this.getTileByCoords(
        this.excavator.excavatorContainer.x +
          135 * -this.excavator.getExcavator().scaleX,
        this.excavator.excavatorContainer.y + 30
      );
      tilesToDig[1] = this.getTileByCoords(
        this.excavator.excavatorContainer.x +
          170 * -this.excavator.getExcavator().scaleX,
        this.excavator.excavatorContainer.y + 30
      );
      tilesToDig[2] = this.getTileByCoords(
        this.excavator.excavatorContainer.x +
          135 * -this.excavator.getExcavator().scaleX,
        this.excavator.excavatorContainer.y + 60
      );
      tilesToDig[3] = this.getTileByCoords(
        this.excavator.excavatorContainer.x +
          170 * -this.excavator.getExcavator().scaleX,
        this.excavator.excavatorContainer.y + 60
      );

      if (tilesToDig[0]) {
        this.excavatorDiggableAreas.length &&
          this.excavatorDiggableAreas.forEach((c) => {
            if (c) c.setTexture(CST.BUILD.CELL_HIGHLIGHT);
          });
      }

      tilesToDig.forEach((tile) => {
        if (tile && !tile.isDigged) {
          const tileToChange = this.excavatorDiggableAreas.find(
            (t) => t.row == tile.row && t.column === tile.column
          );
          if (tileToChange)
            tileToChange.setTexture(CST.BUILD.CELL_HIGHLIGHT_RED);
        }
      });
    }

    if (this.locator) this.locator.setDepth(this.locator.getPlayer().y);

    if (this.excavator && this.excavator.getExcavator().active) {
      this.excavator.excavatorContainer.setDepth(
        this.player.getPlayer().depth + 1
      );
      this.excavator
        .getExcavator()
        .back.setDepth(this.player.getPlayer().depth - 1);
    }

    if (this.player.isWalkingWithShovel) {
      const tile1 = this.getTileByCoords(
        this.player.getX(),
        this.player.getY()
      );
      const tile2 = this.getTileByCoords(
        this.player.getX() + this.player.spine.scaleX * 35,
        this.player.getY()
      );

      if (tile1) {
        this.shovelDiggableAreas.length &&
          this.shovelDiggableAreas.forEach((c) => {
            c.setTexture(CST.BUILD.CELL_HIGHLIGHT);
          });
      }

      [tile1, tile2].forEach((tile) => {
        if (tile && tile.isMarked && !tile.isDigged) {
          const tileToChange = this.shovelDiggableAreas.find(
            (t) => t.row == tile.row && t.column === tile.column
          );
          if (tileToChange)
            tileToChange.setTexture(CST.BUILD.CELL_HIGHLIGHT_RED);
        }
      });
    }

    if (
      Extention.distance(
        this.player.getX(),
        this.player.getY(),
        this.safetyOfficer.getX(),
        this.safetyOfficer.getY()
      ) < 50 &&
      this.player.isWalkingWithShovel &&
      !this.safetyOfficer.gaveShovelHint
    ) {
      this.safetyOfficer.gaveShovelHint = true;
      Extention.showDialogBox(
        this.scene,
        CharacterDialogData.GetKeepDiggingData()
      );
    }

    if (
      this.drone &&
      this.drone.drone.visible &&
      Extention.distance(
        this.drone.drone.x,
        this.drone.drone.y,
        this.locator.getX(),
        this.locator.getY()
      ) < 50 &&
      this.locator.isApprochable
    ) {
      this.locator.isApprochable = false;

      if (this.locator.approchCount === 0) {
        this.drone.canMove = false;
        this.currentDialogue = "locator1";
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetApproachLocator1Data()
        );
        this.locator.approchCount++;
      } else if (this.locator.approchCount === 1) {
        this.drone.canMove = false;
        this.currentDialogue = "locator2";
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetApproachLocator2Data()
        );
        this.locator.approchCount++;
      }
    }

    if (
      this.drone &&
      !this.locator.isApprochable &&
      Extention.distance(
        this.drone.drone.x,
        this.drone.drone.y,
        this.locator.getX(),
        this.locator.getY()
      ) > 320
    ) {
      this.locator.isApprochable = true;
    }

    if (this.excavator) {
      if (this.excavator.getExcavator().back.flipX) {
        this.excavator
          .getExcavator()
          .back.setPosition(
            this.excavator.excavatorContainer.x + 45,
            this.excavator.excavatorContainer.y - 100
          );
      } else {
        this.excavator
          .getExcavator()
          .back.setPosition(
            this.excavator.excavatorContainer.x - 40,
            this.excavator.excavatorContainer.y - 100
          );
      }
    }

    if (this.shovel && this.shovel.active) {
      if (
        Extention.distance(
          this.player.getX(),
          this.player.getY(),
          this.shovel.x,
          this.shovel.y
        ) < 50
      ) {
        this.shovel.pickedUp();
      }
    }

    if (this.key && this.key.active) {
      if (
        Extention.distance(
          this.player.getX(),
          this.player.getY(),
          this.key.x,
          this.key.y
        ) < 50
      ) {
        this.key.pickedUp();
      }
    }

    if (this.setPlayerFollowingExcavator) {
      this.player
        .getPlayer()
        .setPosition(
          this.excavator.excavatorContainer.x,
          this.excavator.excavatorContainer.y - 10
        );
      this.excavator.update();
    }

    if (
      this.drone &&
      this.drone.getDrone().visible &&
      this.drone.getDrone().active
    ) {
      this.drone.update();
      this.drone.updateTVscreen();
    }

    if (this.setPlayerFollowingBulldozer) {
      this.player
        .getPlayer()
        .setPosition(this.bulldozer.x - 30, this.bulldozer.y - 100);
      this.bulldozer.update();
    }
  }

  addBulldozer() {
    Extention.doMove(this, this.player.getPlayer(), 500, 690, 3000);
    this.player.setFlipX(true);
    this.player.playAnimation(SpineCharacterData.AnimationState.Walking);

    // if(isDevelopment) {
    //   this.secondBuildMode = true;
    //   Extention.showGameHUD(this.scene, {show:true})
    //   return
    // }

    this.time.delayedCall(3000, () => {
      // this.placableAreas.forEach(tile =>  {
      //   if(tile.row === 9 && (tile.column == 2 || tile.column == 4 || tile.column == 5 || tile.column == 10)) {
      //     this.digTile(tile, "excavator")
      //   }
      //   if(tile.row === 8)
      //   this.digTile(tile, "excavator")
      // })

      // return
      this.bulldozer = this.add.spine(
        500,
        690,
        CST.LEVEL1_WHITELINING_SPINE.KEY2,
        CST.LEVEL1_WHITELINING_SPINE.ANIM.BULLDOZER_MOVING,
        true
      );
      this.bulldozer.setSkinByName(CST.LEVEL1_WHITELINING_SPINE.SKIN.BULLDOZER);
      this.bulldozer.setScale(-0.5, 0.5).setDepth(999);
      this.cameras.main.startFollow(this.bulldozer);
      this.cameras.main.setZoom(2);
      this.setPlayerFollowingBulldozer = true;
      this.player.stopPlayerMovement();
      this.player.isStopMovement = true;
      this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.player.setFlipX(false);
      this.cameras.main.setBounds(this.bulldozer.x - 100, 200, 1200, 500);
      Extention.doMove(this, this.bulldozer, "+=1000", "+=0", 4000);
      this.bulldozer.dirtInPush = [];
      this.bulldozer.checkY = 660;

      // this.blackOL = this.add.image(this.game.renderer.width/2, this.game.renderer.height/2,
      // CST.COMMON_IMAGES.BLACK_OVERLAY).setScale(50, 50).setDepth(1200).setOrigin(0.5,0.5);
      this.blackOL.setPosition(700, 500);
      this.soil = this.add.spine(
        this.bulldozer.x,
        this.bulldozer.y,
        CST.LEVEL1_WHITELINING_SPINE.KEY3,
        CST.LEVEL1_WHITELINING_SPINE.ANIM.SOIL_ANIMATION,
        true
      );
      this.soil
        .setSkinByName(CST.LEVEL1_WHITELINING_SPINE.SKIN.SOIL)
        .setDepth(this.bulldozer.depth - 1);
      // Extention.fadeOut(this.scene)
      this.time.delayedCall(3500, () => {
        Extention.fadeIn(this, this.blackOL, 1500);
        this.time.delayedCall(1500, () => {
          Extention.fadeOut(this, this.blackOL, 1500);
          this.bulldozer.x = 500;
          Extention.doMove(this, this.bulldozer, "+=1000", "+=0", 4000);
          this.bulldozer.y -= 40;
          this.bulldozer.checkY -= 30;

          this.bulldozer.dirtInPush = [];

          this.setPlayerFollowingBulldozer = false;
          this.bulldozer.destroy();
          this.whiteline.setVisible(false);
          this.placableAreas.forEach((area) => {
            if (area.dirt) area.dirt.destroy();
            if (area.gravel) area.gravel.destroy();
            if (area.coloredPipe) area.coloredPipe.destroy();
            if (area.flag) area.flag.destroy();
            if (area.coloredSquare) area.coloredSquare.destroy();
          });
          this.cameras.main.setZoom(1.35);
          this.cameras.main.stopFollow(this.bulldozer);
          // this.cameras.main.startFollow(this.player.getPlayer())
          this.matter.world.setBounds(
            660,
            330,
            this.whiteline.displayWidth,
            this.whiteline.displayHeight
          );
          this.cameras.main.setBounds(0, 0, 1925, 720);
          this.cameras.main.scrollX = 485;
          this.cameras.main.scrollY = 550;
          this.soil.destroy();

          Extention.doMove(
            this,
            this.player.getPlayer(),
            "-=100",
            270,
            PLAYER_GO_CENTER_DURATION
          ); // here
          this.player.playAnimation(SpineCharacterData.AnimationState.Walking);
          this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
            this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
            this.secondBuildMode = true;
            Extention.showGameHUD(this.scene, { show: true, buildPulse: true });
            this.safetyOfficer.setDepth(1000);
            this.player.setDepth(1000);
          });
        });
      });

      this.bulldozer.update = () => {
        this.soil.setPosition(this.bulldozer.x + 210, this.bulldozer.y - 30);
        // const dirtsWidth = this.bulldozer.dirtInPush.reduce((acc,v)=>{acc += v.displayWidth; return acc},0)

        // this.bulldozer.dirtInPush.forEach((dirt,i) => {
        //   dirt.setPosition(this.bulldozer.x + 160 + dirt.displayWidth*i, this.bulldozer.checkY)
        // })

        // const currentPushTile = this.getTileByCoords(this.bulldozer.x + 160 + dirtsWidth, this.bulldozer.checkY - 10)

        // //console.log(currentPushTile)
        // if(currentPushTile && currentPushTile.dirt && !currentPushTile.isBeingPushed) {
        //   currentPushTile.isBeingPushed = true
        //   const currentPushDirt = currentPushTile.dirt
        //   this.bulldozer.dirtInPush.push(currentPushDirt)

        //  }
      };
    });
  }

  getAreaToPlaceCementPositions() {
    let positions = [];

    for (let r = 0; r < this.areaToPlaceCement.length; ++r) {
      for (let c = 0; c < this.areaToPlaceCement[0].length; ++c) {
        if (this.areaToPlaceCement[r][c] === "c") {
          positions.push({ r, c });
        }
      }
    }
    return positions;
  }
  getAreaToPlaceFoundationsPositions() {
    let positions = [];

    for (let r = 0; r < this.areaToPlaceFoundations.length; ++r) {
      for (let c = 0; c < this.areaToPlaceFoundations[0].length; ++c) {
        if (this.areaToPlaceFoundations[r][c] === "f") {
          positions.push({ r, c });
        }
      }
    }
    return positions;
  }

  addDialoguesHandler() {
    this.eventManager.on(CST.EVENT.HIDE_DIALOG, (data) => {
      switch (this.currentDialogue) {
        case "waterPipe":
          this.waterPipeDialogCompleted();
          break;
        case "gasPipe":
          this.gasPipeDialogCompleted();
          break;
        case "sewerPipe":
          this.sewerPipeDialogCompleted();
          break;
        case "powerPipe":
          this.powerPipeDialogCompleted();
          break;
        case "communicationPipe":
          this.communicationPipeDialogCompleted();
          break;
        case "locator1":
          this.drone.canMove = true;
          break;

        case "locator2":
          this.drone.canMove = true;
          this.openPipesHint();
          this.drone.drone.setVisible(false);
          break;

        case "saferWorkingHere":
          this.saferWorkingHereDialogCompleted();
          break;
        case "whitelinedArea":
          this.whitelinedAreaDialogCompleted();
          break;
        case "twoDays":
          this.twoDaysDialogCompleted();
          break;
        case "constructingAsk":
          this.constructingAskDialogCompleted();
          break;
        case "useLocator":
          this.useLocatorDialogCompleted();
          break;
        case "startDigging":
          this.startDiggingDialogCompleted();
          break;
        case "solidBase":
          this.solidBaseDialogCompleted();
          break;
        case "strongBuilding":
          this.strongBuildingDialogCompleted();
          break;
      }
    });
  }

  openPipesHint() {
    const gw = this.whiteline.x;
    const gh = this.whiteline.y;
    this.popupShadow = this.add
      .image(this.whiteline.x, this.whiteline.y - 50, "shadow")
      .setDisplaySize(gw, gh)
      .setAlpha(0.6)
      .setDepth(900);

    const SCALE = this.whitelinesZoomScale;
    const elements = [];

    const popup = this.add
      .image(this.whiteline.x, this.whiteline.y - 50, KANSAS.POP_UP_MOBILE)
      .setScale(0)
      .setDepth(1000);
    const whiteBG = this.add
      .image(popup.x, popup.y, KANSAS.WHITE_BG)
      .setDepth(999)
      .setScale(0);
    const logo = this.add
      .image(popup.x, popup.y, KANSAS.LOGO)
      .setScale(0)
      .setDepth(999);
    this.tweens.add({
      targets: [popup, whiteBG, logo],
      scale: 1 * SCALE,

      onComplete: () => {
        this.logoSound.play();
        this.tweens.add({
          targets: logo,
          scale: 1.2 * SCALE,
          duration: 500,
          onComplete: () => {
            this.tweens.add({
              targets: logo,
              alpha: 0,
              duration: 500,
              onComplete: () => {
                whiteBG.destroy();

                const bg = this.add
                  .image(popup.x, popup.y, KANSAS.GREEN_BG)
                  .setDisplaySize(
                    popup.displayWidth - 20,
                    popup.displayHeight - 20
                  )
                  .setDepth(999);
                const pipes = this.add
                  .image(popup.x, popup.y + 20, KANSAS.PIPES_ARRANGEMENT)
                  .setDepth(1001)
                  .setScale(SCALE);

                const bar2 = this.add.graphics();
                bar2.fillStyle(0x1f1f1f, 1);
                bar2.setDepth(9999);
                bar2.fillRect(0, 0, pipes.displayWidth, 20);
                bar2.setPosition(
                  pipes.x - pipes.displayWidth / 2,
                  pipes.y - pipes.displayHeight / 2 - 20
                );

                const bar = this.add.graphics();

                bar.fillStyle(0x30651b, 1);
                bar.setDepth(99999);
                bar.fillRect(0, 0, pipes.displayWidth, 20);
                bar.setPosition(
                  pipes.x - pipes.displayWidth / 2,
                  pipes.y - pipes.displayHeight / 2 - 20
                );

                this.tweens.add({
                  targets: bar,
                  scaleX: 0,
                  duration: 3000,
                  onComplete: () => {
                    [
                      this.popupShadow,
                      popup,
                      whiteBG,
                      logo,
                      bg,
                      pipes,
                      bar2,
                      bar,
                    ].forEach((el) => {
                      el.destroy();
                      this.drone.drone.setVisible(true);
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  strongBuildingDialogCompleted() {
    this.thoughtBubbleSkin = CST.THOUGHT_BUBBLE_SPINE.SKIN.FOUNDATION;
    this.player.bubble.setSkinByName(this.thoughtBubbleSkin);
    this.time.addEvent({
      delay: 10 * 1000,
      loop: true,
      callback: () => {
        if (this.fundationPutAmount === 0) {
          this.player.openBubble();
        }
      },
    });
    //Extention.doMove(this, this.safetyOfficer.getPlayer(),"+=280" ,350, PLAYER_GO_CENTER_DURATION);
    //this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking)

    this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
      this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.player.isStopMovement = true;
    });
    // Extention.showGameHUD(this.scene, {show:true,   buildPulse: true}) //
  }

  solidBaseDialogCompleted() {
    //Extention.doMove(this, this.safetyOfficer.getPlayer(),"+=0" ,300, PLAYER_GO_CENTER_DURATION);
    // this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking)

    this.thoughtBubbleSkin = CST.THOUGHT_BUBBLE_SPINE.SKIN.CEMENT;
    this.player.bubble.setSkinByName(this.thoughtBubbleSkin);
    this.time.addEvent({
      delay: 10 * 1000,
      loop: true,
      callback: () => {
        //  || (!this.isAnyTileDiggedWithExcavator && this.setPlayerFollowingExcavator)
        if (this.cementsPutAmount === 0) {
          this.player.openBubble();
        }
      },
    });
    // this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Idle)
    //Extention.stopScene(this.scene, CST.SCENE.UI_SCENE);
    this.player.isStopMovement = true;
    this.hide4THbutton = true;
    Extention.showGameHUD(this.scene, { show: true, buildPulse: true }); //
  }

  cementPut() {
    this.cementsPutAmount++;

    if (this.cementsPutAmount == this.cementsToBePlaced) {
      this.animateCoins(this.player.getX(), this.player.getY());
      this.sound.play("achieve");
      Extention.stopScene(this.scene, CST.SCENE.BUILD_SCENE);

      Extention.showGameHUD(this.scene, {
        show: true,
        slideTopUI: false,
        buildPulse: true,
      });
      this.scene.bringToTop(CST.SCENE.UI_SCENE);

      this.player.isStopMovement = true;
      this.player.stopPlayerMovement();

      //Extention.doMove(this, this.safetyOfficer.getPlayer(),"+=0" ,this.player.getY() - 80, PLAYER_GO_CENTER_DURATION);
      // this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking)

      // this.time.delayedCall(PLAYER_GO_CENTER_DURATION, ()=>{
      //   this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Idle)
      this.currentDialogue = "strongBuilding";
      Extention.showDialogBox(
        this.scene,
        CharacterDialogData.GetStrongBuildingData()
      );
    }
  }
  foundationPut() {
    this.fundationPutAmount++;

    if (this.fundationPutAmount == this.foundationsToBePlaced) {
      this.animateCoins(this.key.x, this.key.y);

      Extention.stopScene(this.scene, CST.SCENE.BUILD_SCENE);

      //   Extention.showGameHUD(this.scene, {
      //     show: true,
      //     slideTopUI: false
      //  });
      //  this.scene.bringToTop(CST.SCENE.UI_SCENE)
      this.locator &&
        this.locator.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.time.delayedCall(2500, () => this.scene.stop(CST.SCENE.UI_SCENE));
      this.addBulldozer();
    }
  }

  getTilesToDigWithExcavator() {
    return this.excavatorDiggableAreas.length;
  }

  startDiggingDialogCompleted() {
    if (this.shovel) return;

    this.time.addEvent({
      delay: 10 * 1000,
      loop: true,
      callback: () => {
        //  || (!this.isAnyTileDiggedWithExcavator && this.setPlayerFollowingExcavator)
        if (!this.isAnyTileDigged) {
          this.player.openBubble();
        }
      },
    });

    if (this.locator) {
      Extention.doMove(
        this,
        this.locator.getPlayer(),
        this.whiteline.x - this.whiteline.displayWidth / 2 - 100,
        "+=0",
        1500
      );
      this.locator.setFlipX(false);
      this.locator.playAnimation(SpineCharacterData.AnimationState.Walking);
    }

    this.setController();
    this.player.isStopMovement = false;
    this.shovel = this.add.image(
      this.safetyOfficer.getX() - 80,
      this.player.getY() - 30,
      IMAGES_V3.SHOVEL
    );
    this.shovel.setDepth(this.shovel.y).setScale(0.4);
    this.shovel.pickedUp = () => {
      this.shovel.destroy();
      this.player.isWalkingWithShovel = true;
      Extention.showGameHUD(this.scene, {
        scene: this,
        show: true,
        leaveOnlyShovelButton: true,
      });
      this.showShovelDiggableAreas();
      // if (isDevelopment) this.dig("shovel");
    };

    this.matter.world.setBounds(
      660,
      330,
      this.whiteline.displayWidth,
      this.whiteline.displayHeight
    );
  }

  useLocatorDialogCompleted() {
    if (this.locatorDialogAmount > 0) return;

    this.locatorDialogAmount++;

    Extention.doMove(
      this,
      this.player.getPlayer(),
      this.whiteline.x - this.whiteline.displayWidth / 2 - 150,
      "+=0",
      PLAYER_GO_CENTER_DURATION
    );
    Extention.doMove(
      this,
      this.locator.getPlayer(),
      this.whiteline.x - this.whiteline.displayWidth / 2,
      "-=150",
      PLAYER_GO_CENTER_DURATION + 1500
    );

    this.player.playAnimation(SpineCharacterData.AnimationState.Walking);
    this.locator.playAnimation(SpineCharacterData.AnimationState.Walking);
    this.player.setFlipX(true);
    this.locator.setFlipX(true);

    this.time.delayedCall(PLAYER_GO_CENTER_DURATION + 1500, () => {
      this.showFlagPlacableTiles();
      //  const colors = [{orange:0xdc6b15}, {yellow:0xece81b}, {blue:0x1b4fef}, {green: 0x54e81a} ,{red:0xe52a1b}]

      this.locator.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.locator.setFlipX(false);
      //if(!isDevelopment)this.locator.getPlayer().setVisible(false)

      this.placableAreas.forEach((area) => area.setVisible(false));

      // Extention.launchNextScene(this.scene, CST.SCENE.FLAGS_BUILD_SCENE, this);
      // this.scene.bringToTop(CST.SCENE.FLAGS_BUILD_SCENE)

      // setTimeout(()=> this.scene.get(CST.SCENE.FLAGS_BUILD_SCENE).putFlag(),1000)

      //  this.addDrone() // TODO / remove it

      this.drone.tv.animate();

      this.time.delayedCall(800 * 5, () => {
        this.drone.addController();
        this.drone.addArrows();
        Extention.showGameHUD(this.scene, {
          show: true,
          leaveOnlyFlagButton: true,
        });
      });

      // Extention.showGameHUD(this.scene, {show:true})
      // if(isDevelopment) {
      //   this.secondBuildMode = true;
      //   Extention.showGameHUD(this.scene, {show:true})
      //   return
      // }
      //this.scene.setActive(true, CST.SCENE.UI_SCENE)
    });
  }

  setItem(item) {
    const txt = item.toLowerCase() + ".png";
    const x = 1925 / 2;
    const y = 720 / 2;
    switch (item) {
      case "BASE":
        this.add.sprite(x, y, txt).setDepth(999).play(txt);
        this.buildings++;
        this["Building-1"].play();
        break;
      case "MAIN_AREA":
        this.add.sprite(x, y, txt).setDepth(1002).play(txt);
        this.buildings++;
        this["Building-3"].play();
        break;
      case "ENTRANCE_STAIRS":
        this.add.sprite(x, y, txt).setDepth(1000).play(txt);
        this.buildings++;
        break;
      case "MIDDLE_U":
        this.add.sprite(x, y, txt).setDepth(1004).play(txt);
        this.buildings++;
        this["Building-0"].play();
        break;
      case "SIDE_S":
        this.add.sprite(x, y, txt).setDepth(1001).play(txt);
        this.buildings++;
        this["Building-2"].play();
        break;
      case "WALL":
        this.add
          .sprite(x, y + 50, "wall_up.png")
          .setDepth(1000)
          .play("wall_up.png");
        this.add
          .sprite(x, y, "wall_down.png")
          .setDepth(1004)
          .play("wall_down.png");
        this["Building-5"].play();
        this.buildings++;
      // case "LAMP":
      //     this.add.image(this.whiteline.x + 155, this.whiteline.y - 85, txt).setDepth(1005)
      //     this.add.image(this.whiteline.x + 155 + 150, this.whiteline.y - 75, txt).setDepth(1005)
      //     this.add.image(this.whiteline.x + 150 + 140, this.whiteline.y + 55, txt).setDepth(1005)
      //     this.add.image(this.whiteline.x + 150 - 70, this.whiteline.y + 55, txt).setDepth(1005)
      //       break
      // case "GARDEN":
      //   this.add.sprite(x, y, txt).setDepth(1000).play(txt)
      //     break
      case "DECORATIONS":
        this.add.sprite(x, y, txt).setDepth(1001).play(txt);
        this.buildings++;
        this["Building-6"].play();
        break;
      case "BUILDING":
        this.add.sprite(x, y, txt).setDepth(1003).play(txt);
        this.buildings++;
        this["Building-4"].play();
        break;
      // case "VERT_WALL":
      //           this.add.image(this.whiteline.x- 470, this.whiteline.y, txt).setDepth(1003)
      //           this.add.image(this.whiteline.x+ 470, this.whiteline.y, txt).setDepth(1003)
      //         break
    }

    if (this.buildings === 8) {
      this.scene.bringToTop(CST.SCENE.UI_SCENE);
      Extention.launchNextScene(this.scene, CST.SCENE.END_SCENE, {
        coinsToAdd: 0,
        startAgainButton: true,
        callback: () => {},
      });
    }
  }

  showExcavatorDiggableAreas() {
    const COLUMNS = this.areaToDigWithExcavator[0].length;
    const ROWS = this.areaToDigWithExcavator.length;
    const config = this.areaToDigWithExcavator;
    const leftUpTileX = 670;
    const leftUpTileY = 350;

    for (let c = 0; c < COLUMNS; ++c) {
      for (let r = 0; r < ROWS; ++r) {
        if (!config[r][c] || config[r][c] !== "d" || this.flags[r][c] !== "n")
          continue;

        const area = this.addHighlightedArea(0, 0, false);
        area.setPosition(
          leftUpTileX + area.displayWidth * c,
          leftUpTileY + area.displayHeight * r
        );
        area.column = c;
        area.row = r;

        this.excavatorDiggableAreas.push(area);

        // if(this.areaToDigWithExcavator[r][c] === "d" && this.flags[r][c] !== "n") {
        //    this.add.image(leftUpTileX + area.displayWidth*c, leftUpTileY + area.displayHeight*r, IMAGES_V2.WHITE_SQUARE)
        // }
      }
    }
  }

  showShovelDiggableAreas() {
    const COLUMNS = this.flags[0].length;
    const ROWS = this.flags.length;
    const config = this.flags;
    const leftUpTileX = 670;
    const leftUpTileY = 350;

    for (let c = 0; c < COLUMNS; ++c) {
      for (let r = 0; r < ROWS; ++r) {
        if (!config[r][c] || config[r][c] === "n") continue;

        const area = this.addHighlightedArea(0, 0, false);
        area.setPosition(
          leftUpTileX + area.displayWidth * c,
          leftUpTileY + area.displayHeight * r
        );
        area.column = c;
        area.row = r;

        this.shovelDiggableAreas.push(area);

        // if(this.areaToDigWithExcavator[r][c] === "d" && this.flags[r][c] !== "n") {
        //    this.add.image(leftUpTileX + area.displayWidth*c, leftUpTileY + area.displayHeight*r, IMAGES_V2.WHITE_SQUARE)
        // }
      }
    }
  }

  showFlagPlacableTiles() {
    const COLUMNS = this.flags[0].length;
    const ROWS = this.flags.length;
    const config = this.flags;
    const leftUpTileX = 670;
    const leftUpTileY = 350;

    for (let c = 0; c < COLUMNS; ++c) {
      for (let r = 0; r < ROWS; ++r) {
        if (!config[r][c]) continue;

        const area = this.addHighlightedArea(0, 0, true);
        area.setPosition(
          leftUpTileX + area.displayWidth * c,
          leftUpTileY + area.displayHeight * r
        );
        area.column = c;
        area.row = r;

        area.pipeColor = config[r][c];

        // if(this.areaToDigWithExcavator[r][c] === "d" && this.flags[r][c] !== "n") {
        //    this.add.image(leftUpTileX + area.displayWidth*c, leftUpTileY + area.displayHeight*r, IMAGES_V2.WHITE_SQUARE)
        // }
      }
    }
  }

  showCementAreas() {
    this.areaToPlaceCementPositions.forEach((pos) => {
      const tile = this.placableAreas
        .find((tile) => tile.row === pos.r && tile.column === pos.c)
        .setDepth(999);
      tile.setVisible(true);
      tile.canPlaceCement = true;
    });

    this.scene.get(CST.SCENE.BUILD_SCENE).placableAreas = this.placableAreas;
  }

  showFoundationAreas() {
    if (this.currentDialogue !== "strongBuilding") return;
    this.areaToPlaceFoundationsPositions.forEach((pos) => {
      const tile = this.placableAreas
        .find((tile) => tile.row === pos.r && tile.column === pos.c)
        .setDepth(999);
      tile.setVisible(true);
      tile.canPlaceFoundation = true;
    });

    this.scene.get(CST.SCENE.BUILD_SCENE).placableAreas = this.placableAreas;
  }

  addHighlightedArea(x, y, push) {
    const area = this.add
      .image(x, y, CST.BUILD.CELL_HIGHLIGHT)
      .setDepth(45)
      .setScale(0.87);
    push && this.placableAreas.push(area);
    return area;
  }
  setVisibleDroneAndTv(visible) {
    if (!this.drone) return;
    this.drone.getDrone().setVisible(visible);
    this.drone.tv.setVisible(visible);
    this.drone.tv.screen.setVisible(visible);
    this.drone.leftArrow.setVisible(visible);
    this.drone.rightArrow.setVisible(visible);
  }

  getTileColorByCoords(x, y) {
    const tile = this.getTileByCoords(x, y);
    if (!tile) return null;
    const color = this.getColorFromTile(tile);
    return color;
  }

  getColorFromTile(tile) {
    const colors = this.colors;
    return colors.find((color) => color[0] === tile.pipeColor[0]);
  }

  getTileByCoords(x, y) {
    return this.placableAreas.find((area) => {
      const hitbox = area.getBounds();
      return (
        x > hitbox.x &&
        x < hitbox.x + hitbox.width &&
        y > hitbox.y &&
        y < hitbox.y + hitbox.height
      );
    });
  }

  getFlagTextureByColor(colorFromArrayOfColors) {
    return IMAGES_V2[`FLAG_${colorFromArrayOfColors.toUpperCase()}`];
  }

  dig(type) {
    //console.log("dig button clicked!")
    let tileToDig = null;
    let animDuration = 0;
    if (this.player.isDigging || this.isPipeDamaged) return;
    this.player.isDigging = true;
    this.player.isStopMovement = true;
    this.player.stopPlayerMovement();

    if (type === "shovel") {
      animDuration = this.player.playAnimationByName(
        SpineCharacterData.AnimationName.SHOVEL_DIG
      );
      //  tileToDig = this.getTileByCoords(this.player.getX() + this.player.spine.scaleX * 40, this.player.getY())
      //  animDuration = 0a
      this.time.delayedCall(animDuration, () => {
        this.player.isStopMovement = false;
        this.player.playAnimationByName(
          SpineCharacterData.AnimationName.SHOVEL_IDLE
        );
        this.player.playAnimation(
          SpineCharacterData.AnimationState.SHOVEL_IDLE
        );
        this.player.isDigging = false;
      });
    }
    if (type === "excavator") {
      this.excavator
        .getExcavator()
        .setAnimation(
          0,
          CST.LEVEL1_WHITELINING_SPINE.ANIM.EXCAVATOR_DIGGING,
          false,
          true
        );
      // tileToDig = this.getTileByCoords(this.excavator.excavatorContainer.x + 135 * -this.excavator.getExcavator().scaleX, this.excavator.excavatorContainer.y + 30)
      animDuration =
        this.excavator.getExcavator().getCurrentAnimation(0).duration * 1000;
      this.excavator.getExcavator().isStopMovement = true;
      this.excavator.excavatorContainer.setVelocityX(0);
      this.excavator.excavatorContainer.setVelocityY(0);
      //  animDuration = 0
      this.time.delayedCall(animDuration, () => {
        this.excavator.getExcavator().isStopMovement = false;
        this.player.isDigging = false;
      });
      //this.add.image(this.excavatorContainer.x + 100, this.excavatorContainer.y, IMAGES_V2.WHITE_SQUARE).setDepth(999)
    }

    //console.log("tileToDig: ",tileToDig)
    //console.log(tileToDig)
    //if(!tileToDig || !tileToDig.isMarked || tileToDig.isDigged) return

    this.time.delayedCall(animDuration, () => {
      if (type === "excavator") {
        // REMOVE IT
        const tilesToDig = []; // this.excavatorDiggableAreas // []
        tilesToDig[0] = this.getTileByCoords(
          this.excavator.excavatorContainer.x +
            135 * -this.excavator.getExcavator().scaleX,
          this.excavator.excavatorContainer.y + 30
        );
        tilesToDig[1] = this.getTileByCoords(
          this.excavator.excavatorContainer.x +
            170 * -this.excavator.getExcavator().scaleX,
          this.excavator.excavatorContainer.y + 30
        );
        tilesToDig[2] = this.getTileByCoords(
          this.excavator.excavatorContainer.x +
            135 * -this.excavator.getExcavator().scaleX,
          this.excavator.excavatorContainer.y + 60
        );
        tilesToDig[3] = this.getTileByCoords(
          this.excavator.excavatorContainer.x +
            170 * -this.excavator.getExcavator().scaleX,
          this.excavator.excavatorContainer.y + 60
        );
        this.digTilesExcavator(tilesToDig, type);
        if (isDevelopment) this.allExcavatorTilesDigged();
      } else {
        // REMOVE IT
        const tilesToDig = []; // this.placableAreas; //
        tilesToDig[0] = this.getTileByCoords(
          this.player.getX(),
          this.player.getY()
        );
        tilesToDig[1] = this.getTileByCoords(
          this.player.getX() + this.player.spine.scaleX * 35,
          this.player.getY()
        );

        this.digTilesShovel(tilesToDig, type);
      }

      this.updateAllTiles();
      if (this.areAllTilesDigged()) {
        console.log("all Tiles with shovel digged!");
        this.allTilesDigged();
      }
    });
  }

  allTilesDigged() {
    this.scene.setActive(false, CST.SCENE.UI_SCENE);
    this.scene.setVisible(false, CST.SCENE.UI_SCENE);
    this.excavator = new Excavator(
      this,
      this.whiteline.x + this.whiteline.displayWidth / 2 + 300,
      this.whiteline.y
    );

    this.createKey();

    this.matter.world.setBounds(
      660,
      50,
      this.whiteline.displayWidth,
      this.game.renderer.height - 50
    );
    this.placableAreas.forEach((tile) => (tile.isMarked = true));

    this.player.isStopMovement = true;
    this.player.stopPlayerMovement();

    Extention.doMove(
      this,
      this.safetyOfficer.getPlayer(),
      "+=200",
      280,
      PLAYER_GO_CENTER_DURATION
    ); // HERE
    this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking);

    this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
      this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.player.isStopMovement = false;
    });
    //  Extention.stopScene(this.scene, CST.SCENE.UI_SCENE);
  }

  fillGravel() {
    const gravel = [];
    this.placableAreas.forEach((tile) => {
      if (!tile.dirt) return;
      tile.gravel = this.add
        .image(tile.x, tile.y, IMAGES_V2.GRAVEL)
        .setDepth(tile.dirt.depth + 1)
        .setAlpha(0);
      gravel.push(tile.gravel);
    });

    this.tweens.add({
      targets: gravel,
      alpha: 1,
      duration: 1000,
    });
  }

  createKey() {
    this.key = this.add.image(
      this.safetyOfficer.getX() - 50,
      this.safetyOfficer.getY() - 20,
      IMAGES_V3.KEY
    );
    this.key.setDepth(this.key.y).setScale(0.35);

    this.key.pickedUp = () => {
      this.scene.get(CST.SCENE.UI_SCENE).fullScreenAndProfileInOut(true);
      this.animateCoins(this.key.x, this.key.y);
      this.sound.play("achieve");

      this.key.destroy();
      this.fillGravel();
      this.player.playAnimationByName(
        SpineCharacterData.AnimationName.PICKING_KEY
      );
      this.player.isStopMovement = true;
      const delay = this.player.spine.getCurrentAnimation(0).duration * 1000;

      this.time.delayedCall(delay, () => {
        this.matter.world.remove(this.player.characterBody);
        this.player.isWalkingWithShovel = false;
        this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
        this.excavator.getExcavator().x =
          this.excavator.getExcavator().x -
          Math.abs(this.excavator.getExcavator().displayWidth / 2);

        Extention.doMove(
          this,
          this.excavator.excavatorContainer,
          this.whiteline.x + this.whiteline.displayWidth / 2 - 100,
          "+=0",
          PLAYER_GO_CENTER_DURATION
        );
        this.player.isStopMovement = true;
        this.player.stopPlayerMovement();

        this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
          this.player.setFlipX(false);
          this.player.playAnimation(SpineCharacterData.AnimationState.Walking);
          Extention.doMove(
            this,
            this.player.getPlayer(),
            this.excavator.excavatorContainer.x - 30,
            this.excavator.excavatorContainer.y,
            1500
          );
          Extention.fadeIn(this, this.blackOL, 1500);
          this.time.delayedCall(1500, () => {
            Extention.showGameHUD(this.scene, {
              show: true,
              scene: this,
              leaveOnlyExcavatorButton: true,
            }); // TODO / code a excavator button
            this.digUntouchableTiles();
            Extention.fadeOut(this, this.blackOL, 1500);

            this.player.setFlipX(true);
            this.excavator.getExcavator().back.setFlipX(false);
            this.setPlayerFollowingExcavator = true;
            this.excavator.addController();
            this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
            this.excavator.addBody();
          });
        });
      });

      this.showExcavatorDiggableAreas();
      this.tilesToDigWithExcavator = this.getTilesToDigWithExcavator();
    };
  }
  addProfileView() {
    var panel = this.add.image(0, 0, CST.PROFILE.coinPanel).setScale(1.15);
    var coin = this.add.image(-50, 15, CST.PROFILE.coin);
    var playerNameText = this.add
      .text(0, -25, localStorage.getItem(CST.STORAGE_KEY.PLAYER_NAME), {
        color: "white",
        fontFamily: "SwisBlack",
        fontSize: "32px",
        align: "center",
        color: "#fff",
        fixedWidth: 200,
      })
      .setOrigin(0.5, 0.5);
    var coinText = this.add
      .text(
        32,
        15,
        Extention.convertInCurrency(localStorage.getItem(CST.STORAGE_KEY.COIN)),
        {
          color: "white",
          fontFamily: "SwisBlack",
          fontSize: "32px",
          align: "left",
          color: "#fff",
          fixedWidth: 120,
        }
      )
      .setOrigin(0.5, 0.5);
    this.coinText = coinText;

    this.profile = this.add.container(150 + 650, 70 + 180, [
      panel,
      playerNameText,
      coin,
      coinText,
    ]);
    this.profile.setDepth(9998);
  }

  fullScreenAndProfileInOut(isIn) {
    var y = isIn ? BUTTON_BOTTOM_OFFSET + 30 : -(BUTTON_BOTTOM_OFFSET + 50);

    this.upUItween = Extention.doMove(
      this,
      [this.fullscreen, this.profile],
      "+=0",
      y,
      400,
      "linear",
      () => {
        this.upUItween = null;
      }
    );
  }
  digUntouchableTiles() {
    const tilesToDig = [];
    const tiles = [
      { r: 1, c: 1 + 4 },
      { r: 2, c: 1 + 4 },
      { r: 3, c: 1 + 4 },
      { r: 4, c: 1 + 4 },
      { r: 6, c: 1 + 4 },
      { r: 7, c: 1 + 4 },
      { r: 8, c: 1 + 4 },
      { r: 8, c: 2 + 4 },
      { r: 8, c: 3 + 4 },
      { r: 6, c: 3 + 4 },
    ];

    tiles.forEach((pos) => {
      const tile = this.placableAreas.find(
        (t) => t.row === pos.r && t.column === pos.c
      );
      tilesToDig.push(tile);
    });

    this.digTilesExcavator(tilesToDig, "excavator");
    //if (isDevelopment) this.tilesDiggedWithExcavator = 999;
  }

  animateCoins(x, y) {
    const COINS_QUANTITY = 10;
    for (let i = 0; i < COINS_QUANTITY; ++i) {
      const coin = this.add
        .image(x, y, "coin")
        .setDepth(9999)
        .setVisible(false);

      this.time.delayedCall(i * 100, () => {
        this.sound.play("coin");
        this.animateCoin(coin);
        coin.setVisible(true);
      });
    }

    // this.time.delayedCall(1500, ()=>{
    //  Extention.addCoins(this.scene, 20);
    // })
  }

  animateCoin(coin) {
    this.tweens.add({
      targets: coin,
      y: "+=15",
      x: "+=15",
      duration: 100,
      onComplete: () => {
        this.tweens.add({
          targets: coin,
          y: 80,
          x: 600,
          duration: 1000,
          onComplete: () => {
            coin.destroy();
            Extention.addCoins(this.scene, 2);
          },
        });
      },
    });
  }

  areAllTilesDigged() {
    return (
      this.placableAreas.filter((tile) => tile.isMarked).length ===
      this.placableAreas.filter((tile) => tile.isDigged).length
    );
  }
  getFlag(r, c) {
    return this.placedFlags.find((flag) => flag.row === r && flag.column === c);
  }

  pipeDamagedRestart(cb) {
    this.pipeDamagedCB = cb;
    this.time.delayedCall(3000, () => {
      this.currentDialogue = "waterPipe";
      Extention.showDialogBox(
        this.scene,
        CharacterDialogData.GetWaterPipeDamageData()
      );
    });
  }

  pipeDamaged(tile) {
    this.pipeDamagedCB = null;
    switch (tile.pipeColor) {
      case "g":
        this.sewer = this.add.spine(
          tile.x,
          tile.y,
          "sewer",
          "animation",
          false
        );
        this.sewer.setSkinByName("default");
        this.sewer.setScale(0.2).setDepth(tile.depth + 20);
        this.pipeDamagedRestart(() => this.sewer.destroy());

        break;
      case "y":
        this.gas = this.add.spine(tile.x, tile.y, "gas", "animation", true);
        this.gas.setSkinByName("default").setDepth(tile.depth + 20);
        this.pipeDamagedRestart(() => this.gas.destroy());

        break;
      case "b":
        this.water = this.add.spine(tile.x, tile.y, "water", "animation", true);
        this.water.setSkinByName("default");
        this.water.setScale(0.2).setDepth(tile.depth + 20);

        this.pipeDamagedRestart(() => this.water.destroy());
        break;
      case "r":
        this.electricSign = this.add
          .image(tile.x, tile.y, "electricSign")
          .setDepth(9999);
        this.tweens.add({
          targets: this.electricSign,
          alpha: 0,
          duration: 0,
          hold: 500,
          delay: 500,
          repeatDelay: 500,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.electricSign.destroy();

            this.tweens.add({
              targets: this.cameras.main,
              scrollX: 750,
              scrollY: -200,
              duration: 2000,
              zoom: 1.85,
              yoyo: true,
              hold: 3000,
              onComplete: () => {
                this.electricSign.destroy();
              },
            });

            this.time.delayedCall(2000, () => {
              this.power = this.add
                .spine(1400, 200, "power", "animation", false)
                .setDepth(999);
              this.power.setSkinByName("default");

              const delay = this.power.getCurrentAnimation(0).duration * 1000;

              this.time.delayedCall(delay, () => {
                this.power.destroy();
                this.currentDialogue = "waterPipe";
                Extention.showDialogBox(
                  this.scene,
                  CharacterDialogData.GetWaterPipeDamageData()
                );
              });
            });
          },
        });

        break;
      case "o":
        this.showNoWifi();
        break;
    }
  }

  digTilesExcavator(tilesToDig, type) {
    tilesToDig.forEach((tile) => {
      if (!tile) return;
      if (tile.isDigged && tile.isDiggedWithExcavator) return;
      if (tile.isDigged && !tile.isDiggedWithExcavator) {
        if (this.isPipeDamaged) return;
        this.isPipeDamaged = true;
        this.pipeDamaged(tile);

        return;
      }
      const txtData = this.getTileDiggedTxt(tile);

      if (!this.isAnyTileDiggedWithExcavator) {
        this.thoughtBubbleSkin = CST.THOUGHT_BUBBLE_SPINE.SKIN.EXCAVATOR;
        this.isAnyTileDiggedWithExcavator = true;
      }

      tile.dirt = this.add
        .image(tile.x, tile.y, IMAGES_V3[txtData.txt])
        .setFlipX(txtData.flip)
        .setDepth(this.whiteline.depth + 1);
      tile.isDigged = true;
      tile.isDiggedWithExcavator = true;

      if (this.flags[tile.row][tile.column] === "n") {
        const i = this.excavatorDiggableAreas.findIndex(
          (t) => t.row === tile.row && t.column === tile.column
        );
        if (i > -1) this.excavatorDiggableAreas[i].destroy();
        if (i > -1) this.excavatorDiggableAreas.splice(i, 1);
        // console.log("digged tile txt: ",txtData)

        this.updateExcavatorDiggedTiles(tile);
      }
    });
  }

  addPipeOverDigged(tile, _pipe, color) {
    let pipe = null;
    const pipeDir = _pipe.replace(".png", "").split("_");
    const isStraight = pipeDir.length === 2;
    const isElbow = pipeDir.length === 3;

    if (isStraight || _pipe.includes("cross")) {
      pipe = this.add.image(tile.x, tile.y, PIPES.STRAIGHT);
      if (pipeDir[1] === "v") {
        pipe.setAngle(90);
      }
    } else {
      pipe = this.add.image(tile.x, tile.y, PIPES.ELBOW);
      if (pipeDir[1] === "h") pipe.setFlipY(true);
      if (pipeDir[1] === "h" && color === "yellow") {
        pipe.setFlipX(true);
      }
      if (pipeDir[1] === "v" && color !== "yellow") pipe.setFlipX(true);
    }

    let tint = "";
    if (color === "yellow") {
      tint = 0xf7fe22;
    } else if (color === "blue") {
      tint = 0x2260ff;
    } else if (color === "green") {
      tint = 0x25fe21;
    } else if (color === "red") {
      tint = 0xff3822;
    } else if (color === "orange") {
      tint = 0xffa922;
    }

    pipe
      .setTint(tint)
      .setDepth(tile.depth + 3)
      .setAlpha(0.5);
    return pipe;
  }

  digTilesShovel(tilesToDig, type) {
    tilesToDig.forEach((tile) => {
      if (!tile || tile.isDigged || !tile.isMarked) return;

      const tileFlag = this.getFlag.call(this, tile.row, tile.column);

      const pipe = this.getTilePipeTexture(tile);
      tile.coloredPipe = this.addPipeOverDigged(tile, pipe, tile.flag.color);

      const notRemoval = this.getNotRemovalFlagsConfig();

      if (tileFlag) {
        if (notRemoval[tile.row][tile.column] !== "X") {
          tileFlag.destroy();
          tileFlag.coloredSquare.destroy();
        }

        this.isAnyTileDigged = true;
      }

      const txtData = this.getTileDiggedTxt(tile);
      const i = this.shovelDiggableAreas.findIndex(
        (t) => t.row === tile.row && t.column === tile.column
      );
      this.shovelDiggableAreas[i].destroy();
      this.shovelDiggableAreas.splice(i, 1);
      // console.log("digged tile txt: ",txtData)
      tile.dirt = this.add
        .image(tile.x, tile.y, IMAGES_V3[txtData.txt])
        .setFlipX(txtData.flip)
        .setDepth(this.whiteline.depth + 1);
      tile.isDigged = true;
    });
  }

  updateExcavatorDiggedTiles(tile) {
    if (this.isTileToDigWithExcavator(tile)) this.tilesDiggedWithExcavator++;

    if (
      this.tilesDiggedWithExcavator >= this.tilesToDigWithExcavator &&
      this.currentDialogue != "solidBase"
    ) {
      console.log("digged all needed tiles!");
      this.tilesDiggedWithExcavator = -1000000000000;
      this.allExcavatorTilesDigged();
    }
  }

  allExcavatorTilesDigged() {
    this.animateCoins(this.player.getX(), this.player.getY());
    this.sound.play("achieve");

    this.setPlayerFollowingExcavator = false;
    this.matter.world.remove(this.excavator.excavatorBody);
    this.player.addPhysics();
    this.excavator.getExcavator().isStopMovement = true;
    this.player.isStopMovement = true;
    this.matter.world.setBounds(
      660,
      330,
      this.whiteline.displayWidth,
      this.whiteline.displayHeight + 40
    );
    this.excavator.getExcavator().setVisible(false);
    this.excavator.getExcavator().back.setVisible(false);

    // Extention.doMove(this, this.safetyOfficer.getPlayer(),"+=0" ,this.player.getY() - 80, PLAYER_GO_CENTER_DURATION);
    // this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking)

    // this.time.delayedCall(PLAYER_GO_CENTER_DURATION, ()=>{
    // this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Idle)

    //})
    this.scene.stop(this.scene.get(CST.SCENE.UI_SCENE));
    const callback = () => {
      Extention.doMove(
        this,
        this.player.getPlayer(),
        "-=20",
        350,
        PLAYER_GO_CENTER_DURATION
      );
      this.player.playAnimation(SpineCharacterData.AnimationState.Walking);
      this.player.isStopMovement = true;

      this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
        this.player.setFlipX(false);
        this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
        this.currentDialogue = "solidBase";
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetSolidBaseData()
        );
      });
    };

    if (isDevelopment) {
      callback();
      return;
    }
    this.scene.bringToTop(CST.SCENE.UI_SCENE);
    Extention.launchNextScene(this.scene, CST.SCENE.END_SCENE, {
      coinsToAdd: 60,
      callback: () => {
        //Extention.showGameHUD(this.scene, {show:true})
        callback();
      },
    });
    //Extention.doMove(this, this.excavatorContainer, this.whiteline.x + this.whiteline.displayWidth, "+=0")
  }

  isTileToDigWithExcavator(tile) {
    return this.areaToDigWithExcavator[tile.row][tile.column] === "d";
  }

  updateAllTiles() {
    this.placableAreas.forEach((tile) => {
      if (tile.isDigged) {
        const txtData = this.getTileDiggedTxt(tile);

        tile.dirt.setTexture(IMAGES_V3[txtData.txt]).setFlipX(txtData.flip);
        if (txtData.txt === "NORMAL") {
          tile.dirt.setDepth(tile.dirt.depth + 1);
          if (tile.coloredPipe) tile.coloredPipe.setDepth(tile.dirt.depth + 1);
          if (tile.gravel) tile.gravel.setDepth(tile.dirt.depth + 2);
        }
      }
    });
  }

  isTileDigged(r, c) {
    return this.placableAreas.find(
      (tile) => tile.row === r && tile.column === c && tile.isDigged
    );
  }

  getTileDiggedTxt(tile) {
    const f = this.flags;
    const r = tile.row;
    const c = tile.column;
    let flip = false;

    const isD = this.isTileDigged.bind(this);

    let txt = "SINGLE";

    const isUpD = isD(r - 1, c);
    const isLeftD = isD(r, c - 1);
    const isRightD = isD(r, c + 1);
    const isDownD = isD(r + 1, c);

    if (!isLeftD && !isRightD && !isUpD && !isDownD) {
      txt = "SINGLE";
    } else if ((!f[r - 1] || !isUpD) && !isLeftD && !isRightD && isDownD) {
      txt = "UP";
    } else if ((!f[r - 1] || !isUpD) && !isLeftD && isRightD && isDownD) {
      txt = "LEFT_UP";
    } else if ((!f[r - 1] || !isUpD) && isLeftD && !isRightD && isDownD) {
      txt = "LEFT_UP";
      flip = true;
    } else if (f[r - 1] && isUpD && !isLeftD && isRightD && !isDownD) {
      txt = "LEFT_DOWN";
    } else if (f[r - 1] && isUpD && isLeftD && !isRightD && !isDownD) {
      txt = "LEFT_DOWN";
      flip = true;
    } else if ((!f[r + 1] || !isDownD) && !isLeftD && !isRightD) {
      txt = "DOWN";
    } else if ((!f[r - 1] || !isUpD) && isLeftD && isRightD /* && isDownD*/) {
      txt = "TOP";
    } else if ((!f[r - 1] || !isUpD) && isLeftD && !isRightD && !isDownD) {
      txt = "RIGHT";
    } else if ((!f[r - 1] || !isUpD) && !isLeftD && isRightD && !isDownD) {
      txt = "RIGHT";
      flip = true;
    } else if ((isLeftD && isRightD) || (isUpD && isDownD)) {
      txt = "NORMAL";
    }
    return { txt, flip };
  }

  getTilePipeTexture(tile) {
    const f = this.flags;
    const r = tile.row;
    const c = tile.column;

    const curColor = f[r][c];

    //console.log(curColor)
    let txt = "";

    if (curColor === "n") {
      txt = "n";
    }
    if (curColor.length === 2) {
      txt = `${curColor[0]}_cross_${curColor[1]}`;
    } else if (
      (f[r + 1] && f[r + 1][c].length === 2) ||
      ((!f[r + 1] || curColor === f[r + 1][c] || curColor === f[r - 1][c]) &&
        curColor != f[r][c - 1] &&
        curColor != f[r][c + 1])
    ) {
      txt = `${curColor}_v`;
    } else if (
      (curColor === f[r][c + 1] || curColor === f[r][c - 1]) &&
      curColor != f[r - 1][c] &&
      curColor != (!f[r + 1] || f[r + 1][c])
    ) {
      txt = `${curColor}_h`;
    } else if (
      (curColor === f[r][c - 1] && curColor === f[r + 1][c]) ||
      (curColor === f[r + 1][c] && curColor === f[r][c + 1])
    ) {
      txt = `${curColor}_h_v`;
    } else if (
      (curColor === f[r - 1][c] && curColor === f[r][c + 1]) ||
      (curColor === f[r - 1][c] && curColor === f[r][c - 1])
    ) {
      txt = `${curColor}_v_h`;
    }

    if (
      f[r][c - 1] &&
      f[r][c + 1] &&
      f[r][c - 1].length === 2 &&
      f[r][c + 1].length === 2
    ) {
      // it handles one special case with yellow pipe
      txt = `${curColor}_h`;
    }

    // if(txt === "") console.log("txt not found, color:", curColor)

    return PIPES[txt.toUpperCase()];
  }

  allFlagsMarked() {
    // this.scene.setVisible(false, CST.SCENE.UI_SCENE)
    // this.scene.setActive(false, CST.SCENE.UI_SCENE)
    if (!this.drone) {
      // debug
      this.scene.stop(this.scene.get(CST.SCENE.UI_SCENE));
      Extention.doMove(
        this,
        this.player.getPlayer(),
        this.whiteline.x - 200,
        "+=0",
        PLAYER_GO_CENTER_DURATION
      );
      Extention.doMove(
        this,
        this.safetyOfficer.getPlayer(),
        this.whiteline.x,
        "+=150",
        PLAYER_GO_CENTER_DURATION
      );
      this.currentDialogue = "startDigging";
      this.player.isStopMovement = true;
      this.time.delayedCall(1000, () =>
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetStartDiggingData()
        )
      );

      return;
    }

    this.drone.getDrone().destroy();
    this.drone.tv.destroy();
    this.drone.tv.screen.destroy();
    this.drone.leftArrow.destroy();
    this.drone.rightArrow.destroy();

    this.scene.stop(this.scene.get(CST.SCENE.UI_SCENE));
    const cb = () => {
      Extention.doMove(
        this,
        this.player.getPlayer(),
        this.whiteline.x - 200,
        "+=0",
        PLAYER_GO_CENTER_DURATION
      );
      Extention.doMove(
        this,
        this.safetyOfficer.getPlayer(),
        this.whiteline.x,
        "+=150",
        PLAYER_GO_CENTER_DURATION
      );
      this.safetyOfficer.playAnimation(
        SpineCharacterData.AnimationState.Walking
      );

      this.player.setFlipX(false);
      this.safetyOfficer.setFlipX(true);
      this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
        this.safetyOfficer.playAnimation(
          SpineCharacterData.AnimationState.Idle
        );
        this.player.playAnimation(SpineCharacterData.AnimationState.Idle);

        this.currentDialogue = "startDigging";
        this.player.isStopMovement = true;
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetStartDiggingData()
        );
      });
    };

    if (isDevelopment) {
      cb();
      return;
    }

    Extention.launchNextScene(this.scene, CST.SCENE.END_SCENE, {
      coinsToAdd: 60,
      callback: () => {
        cb();
      },
    });
  }

  constructingAskDialogCompleted() {
    Extention.doMove(
      this,
      this.safetyOfficer.getPlayer(),
      this.whiteline.x + this.whiteline.displayWidth / 2,
      "-=150",
      PLAYER_GO_CENTER_DURATION + 1000
    );
    this.safetyOfficer.setFlipX(false);
    this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking);

    this.matter.world.setBounds(
      660,
      330,
      this.whiteline.displayWidth - 75,
      this.whiteline.displayHeight - 30
    );
    this.drone = new Drone(this);
    this.drone.addTV();
    this.drone.tv.screen.setAlpha(0);

    this.time.delayedCall(PLAYER_GO_CENTER_DURATION + 1000, () => {
      this.safetyOfficer.setFlipX(true);
      this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.time.delayedCall(2000, () => {
        this.currentDialogue = "useLocator";
        Extention.showDialogBox(
          this.scene,
          CharacterDialogData.GetUseLocatorData()
        );
      });
    });

    // this.add.image(this.whiteline.x - 20, this.whiteline.y - 20, IMAGES_V2.PIPE_ARRANGEMENT).setScale(0.9)
  }

  twoDaysDialogCompleted() {
    Extention.doMove(
      this,
      this.player.getPlayer(),
      this.whiteline.x - this.whiteline.displayWidth / 2 - 150,
      "+=0",
      PLAYER_GO_CENTER_DURATION
    );
    Extention.doMove(
      this,
      this.safetyOfficer.getPlayer(),
      this.whiteline.x - this.whiteline.displayWidth / 2 - 150,
      "+=0",
      PLAYER_GO_CENTER_DURATION + 500
    );
    this.player.setFlipX(true);

    this.player.playAnimation(SpineCharacterData.AnimationState.Walking);
    this.safetyOfficer.playAnimation(SpineCharacterData.AnimationState.Walking);

    this.time.delayedCall(DELAY_BEFORE_NIGHT, () => {
      const blackLayer = this.add
        .image(
          this.whiteline.x,
          this.whiteline.y,
          CST.COMMON_IMAGES.BLACK_OVERLAY
        )
        .setAlpha(0)
        .setDisplaySize(this.game.renderer.width, this.game.renderer.height)
        .setDepth(9999);
      this.nightFallSound.play();

      this.tweens.add({
        targets: blackLayer,
        alpha: 1,
        yoyo: true,
        repeat: 1,
        duration: DAY_NIGHT_DURATION,
        onYoyo: () => this.daySound.play(),
        onRepeat: () => this.nightFallSound.play(),
        onComplete: () => {
          this.player.setFlipX(false);
          this.safetyOfficer.setFlipX(false);

          blackLayer.destroy();

          this.setUpLocator(
            this.whiteline.x + this.whiteline.displayWidth / 2 + 150,
            this.player.getY()
          );
          this.locator.playAnimation(SpineCharacterData.AnimationState.Walking);

          Extention.doMove(
            this,
            this.locator.getPlayer(),
            this.whiteline.x + 100,
            "+=0",
            PLAYER_GO_CENTER_DURATION
          );
          Extention.doMove(
            this,
            this.player.getPlayer(),
            this.whiteline.x - 100,
            "+=0",
            PLAYER_GO_CENTER_DURATION
          );
          Extention.doMove(
            this,
            this.safetyOfficer.getPlayer(),
            this.whiteline.x,
            "+=0",
            PLAYER_GO_CENTER_DURATION
          );

          this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
            this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
            this.safetyOfficer.playAnimation(
              SpineCharacterData.AnimationState.Idle
            );
            this.locator.playAnimation(SpineCharacterData.AnimationState.Idle);
            this.currentDialogue = "constructingAsk";
            Extention.showDialogBox(
              this.scene,
              CharacterDialogData.GetConstructingAskData()
            );
          });
        },
      });
    });
  }

  saferWorkingHereDialogCompleted() {
    CheckListData.setCompleted(9);
    //Extention.showCheckList(this.scene)
    Extention.doMove(
      this,
      this.player.getPlayer(),
      610,
      500,
      PLAYER_INIT_GO_SO_DURATION
    );
    this.player.playAnimation(SpineCharacterData.AnimationState.Walking);

    this.time.delayedCall(PLAYER_INIT_GO_SO_DURATION, () => {
      this.player.playAnimation(SpineCharacterData.AnimationState.Idle);
      this.cameras.main.stopFollow(this.player.getPlayer());
      this.createWhitelines();

      // this.scene.setVisible(false, CST.SCENE.UI_SCENE)
      // this.scene.setActive(false, CST.SCENE.UI_SCENE)

      this.tweens.add({
        targets: this.cameras.main,
        scrollX: 485,
        duration: WHITELINS_ZOOM_SCROLL_DURATION,
        onComplete: () => {
          //this.constructingAskDialogCompleted() //
          this.tweens.add({
            targets: this.cameras.main,
            zoom: WHITELINES_ZOOM,
            duration: WHITELINS_ZOOM_SCROLL_DURATION,
            scrollY: 550,
            onComplete: () => this.setWhitelinesInteractive(),
          });
        },
      });
    });
  }

  whitelinedAreaDialogCompleted() {
    if (isDevelopment) {
      this.currentDialogue = "twoDays";
      Extention.showDialogBox(this.scene, CharacterDialogData.GetTwoDaysData());
      return;
    }
    const gw = this.whiteline.x;
    const gh = this.whiteline.y;
    this.popupShadow = this.add
      .image(this.whiteline.x, this.whiteline.y - 50, "shadow")
      .setDisplaySize(gw, gh)
      .setAlpha(0.6)
      .setDepth(900);

    const SCALE = this.whitelinesZoomScale;
    const elements = [];
    const popup = this.add
      .image(this.whiteline.x, this.whiteline.y - 50, KANSAS.POP_UP_MOBILE)
      .setScale(0)
      .setDepth(1000);
    const whiteBG = this.add
      .image(popup.x, popup.y, KANSAS.WHITE_BG)
      .setDepth(999)
      .setScale(0);
    const logo = this.add
      .image(popup.x, popup.y, KANSAS.LOGO)
      .setScale(0)
      .setDepth(999);
    this.tweens.add({
      targets: [popup, whiteBG, logo],
      scale: 1 * SCALE,

      onComplete: () => {
        this.logoSound.play();
        this.tweens.add({
          targets: logo,
          scale: 1.2 * SCALE,
          duration: 500,
          onComplete: () => {
            this.tweens.add({
              targets: logo,
              alpha: 0,
              duration: 500,
              onComplete: () => {
                whiteBG.destroy();
                let circle, greenBG, checkmark, thankYouText;

                const upperBar = this.add
                  .image(
                    popup.x - popup.displayWidth / 2 + 15,
                    popup.y - 135,
                    KANSAS.GREEN_BG
                  )
                  .setDisplaySize(1120 * SCALE, 65 * SCALE)
                  .setOrigin(0, 0.5)
                  .setDepth(999);
                upperBar.text = this.add
                  .text(
                    upperBar.x + 20,
                    upperBar.y - 5,
                    "Select your location to request a Locator visit!",
                    { fontFamily: "SwisBlack", fontSize: 30 }
                  )
                  .setOrigin(0, 0.5)
                  .setDepth(999)
                  .setScale(SCALE);

                const map = this.add
                  .image(popup.x, popup.y + 20, KANSAS.MAP)
                  .setDepth(999)
                  .setScale(SCALE);
                const dot = this.add
                  .image(popup.x + 300, popup.y + 50, KANSAS.PLAYER_LOCATION)
                  .setAlpha(0.5)
                  .setDepth(999)
                  .setScale(SCALE);
                dot.setInteractive().on("pointerdown", () => {
                  if (dot.clicked) return;
                  this.dotSound.play();
                  circle = this.add
                    .image(dot.x, dot.y, KANSAS.CIRCLE)
                    .setDepth(999)
                    .setScale(SCALE);
                  dot.clicked = true;
                });

                this.tweens.add({
                  targets: dot,
                  scale: 1.2 * SCALE,
                  alpha: 1,
                  yoyo: true,
                  repeat: -1,
                });

                const button = this.add
                  .image(popup.x + 250, popup.y + 140, KANSAS.REQUEST_BUTTON)
                  .setDepth(999)
                  .setDisplaySize(180, 30);
                button.text = this.add
                  .text(button.x, button.y, "Request a Locate", {
                    fontFamily: "SwisBlack",
                    fontSize: 30,
                  })
                  .setOrigin(0.5)
                  .setDepth(999)
                  .setScale(SCALE);

                button.setInteractive().on("pointerdown", () => {
                  if (button.isClicked || !dot.clicked) return;
                  this.okSound.play();
                  button.isClicked = true;
                  greenBG = this.add
                    .image(popup.x, popup.y, KANSAS.GREEN_BG)
                    .setDepth(999)
                    .setScale(SCALE);
                  checkmark = this.add
                    .image(popup.x, popup.y - 50, KANSAS.CHECKMARK)
                    .setDepth(999)
                    .setScale(SCALE);
                  thankYouText = this.add
                    .text(
                      popup.x,
                      popup.y,
                      "Thank you for contacting Kansas811. We help to ensure that you complete your digging project safely! " +
                        "Your request has been submitted. Please wait for 2 business days for a Locator to visit your construction site.",
                      {
                        fontFamily: "SwisBlack",
                        fontSize: 30,
                        wordWrap: { width: map.displayWidth },
                      }
                    )
                    .setOrigin(0.5, 0)
                    .setDepth(999)
                    .setScale(SCALE);

                  this.time.delayedCall(CLOSE_MOBILE_POPUP_DELAY, () => {
                    elements.push(
                      popup,
                      map,
                      dot,
                      circle,
                      button,
                      button.text,
                      greenBG,
                      checkmark,
                      thankYouText,
                      upperBar.text,
                      upperBar,
                      logo
                    );
                    elements.forEach((element) => element && element.destroy());
                    CheckListData.setCompleted(11);
                    this.popupShadow.destroy();
                    //Extention.showCheckList(this.scene)
                    this.currentDialogue = "twoDays";
                    Extention.showDialogBox(
                      this.scene,
                      CharacterDialogData.GetTwoDaysData()
                    );
                  });
                });
              },
            });
          },
        });
      },
    });
  }

  createWhitelines() {
    let iterator = 1;
    for (let i = 0; i < 5; ++i) {
      for (let j = 0; j < 12; j++) {
        const whiteline = this.add.image(
          695 + 87 * 0.9 * j,
          360 + 87 * 0.9 * i,
          `Whitelining-Gameplay_${iterator}`
        );
        if (whiteline.texture.key === "__MISSING") {
          whiteline.setTexture(`Whitelining-Gameplay_${iterator}-Empty`);
        }
        this.whitelines.push(whiteline);
        iterator++;
      }
    }

    this.whitelines.forEach((whiteline) => whiteline.setScale(0));
    this.tweens.add({
      targets: this.whitelines,
      scale: 0.9,
      duration: 2000,
      onComplete: () => {
        this.tweens.add({
          targets: this.whitelines,
          scale: 0.85,
          duration: 400,
        });
      },
    });

    this.raiseSound.play();
    const angles = [90, 180, 270];
    this.whitelines.forEach((whiteline) => {
      whiteline.canRotate = true;
      this.tweens.add({
        targets: whiteline,
        angle: angles[Phaser.Math.Between(0, 2)],
        duration: 2000,
      });
    });
  }

  setWhitelinesInteractive() {
    if (isDevelopment) {
      this.handlePuzzlesWin();
      return;
    }

    this.whitelines.forEach((whiteline) => {
      whiteline.setInteractive().on("pointerdown", () => {
        if (!whiteline.canRotate) return;
        this.rotationSound.play();
        whiteline.canRotate = false;

        this.tweens.add({
          targets: whiteline,
          duration: 400,
          angle: "+=90",
          onComplete: () => {
            whiteline.canRotate = true;

            if (
              whiteline.angle === 0 ||
              whiteline.texture.key.includes("Empty")
            ) {
              this.correctRotation.play();
              this.tweens.add({
                targets: whiteline,
                scale: 0.89,
                duration: 400,
              });

              whiteline.canRotate = false;

              this.checkWin();
            }
          },
        });
      });
    });
  }

  checkWin() {
    const whitelinesToCheck = this.whitelines.filter(
      (whiteline) => !whiteline.texture.key.includes("Empty")
    );
    // isDevelopment || 1 ||
    if (whitelinesToCheck.every((whiteline) => whiteline.angle === 0)) {
      //
      //this.showFlagPlacableTiles(); // REMOVE IT
      Extention.showGameHUD(this.scene, {
        show: true,
        leaveOnlyFlagButton: true,
      });
      // REMOVE IT
      // this.secondBuildMode = true;
      // Extention.showGameHUD(this.scene, {show:true, buildPulse:true})
      //  return
      this.tweens.add({
        targets: this.whitelines,
        alpha: 0,
        duration: 500,
        onComplete: () => this.handlePuzzlesWin(),
      });
    }
  }

  handlePuzzlesWin() {
    this.scene.stop(CST.SCENE.UI_SCENE);
    Extention.fadeIn(this, this.whiteline, 1500);
    const callback = () => {
      //Extention.showGameHUD(this.scene, {show:true})
      CheckListData.setCompleted(10);
      //Extention.showCheckList(this.scene)

      this.safetyOfficer.getPlayer().x = 1740;
      this.safetyOfficer.getPlayer().y = this.player.getY();

      Extention.doMove(
        this,
        this.player.getPlayer(),
        1200,
        "+=0",
        PLAYER_GO_CENTER_DURATION
      );
      Extention.doMove(
        this,
        this.safetyOfficer.getPlayer(),
        1270,
        "+=0",
        PLAYER_GO_CENTER_DURATION
      );

      this.player.playAnimation(SpineCharacterData.AnimationState.Walking);
      this.safetyOfficer.playAnimation(
        SpineCharacterData.AnimationState.Walking
      );

      this.time.delayedCall(PLAYER_GO_CENTER_DURATION, () => {
        const playerDuration = this.player.playAnimationByName(
          SpineCharacterData.AnimationName.PoseIntro5
        );
        const animationDuration = this.safetyOfficer.playAnimationByName(
          SpineCharacterData.AnimationName.PoseIntro5
        );
        //this.safetyOfficer.playAnimationByName(SpineCharacterData.AnimationName.PoseIntro5, true);

        const duration =
          playerDuration > animationDuration
            ? playerDuration
            : animationDuration;
        this.time.delayedCall(duration, () => {
          this.safetyOfficer.playAnimationByName(
            SpineCharacterData.AnimationState.Idle,
            true
          );
          this.player.playAnimationByName(
            SpineCharacterData.AnimationState.Idle,
            true
          );

          this.currentDialogue = "whitelinedArea";
          Extention.showDialogBox(
            this.scene,
            CharacterDialogData.GetWhitelinedAreaData()
          );
        });
      });
    };
    if (isDevelopment) {
      callback();
      return;
    }

    Extention.launchNextScene(this.scene, CST.SCENE.END_SCENE, {
      coinsToAdd: 60,
      callback: () => {
        callback();
      },
    });
  }

  getNotRemovalFlagsConfig() {
    // prettier-ignore
    return [ // change n to '0'
      ['n','n','n','n','X','n','X','n','n','n','n','n','n','n','X','n','n','n','n','n','n','X','n','n','n','n','n'],
      ['n','n','n','n','r','n','g','n','n','y','y','y','y','y','yb','y','y','y','y','y','y','y','n','n','n','n','n'],
      ['n','n','n','n','r','n','g','n','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n','n'],
      ['n','n','n','n','r','n','g','n','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n','n'],
      ['n','n','n','n','r','n','g','n','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n','n'],
      ['X','y','y','y','ry','y','gy','y','y','y','n','n','n','n','b','b','n','n','n','n','n','n','n','n','n','n','n'],
      ['n','n','n','n','r','n','g','n','n','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n'],
      ['n','n','n','n','r','n','g','g','g','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n'],
      ['n','n','n','n','r','n','n','n','g','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n'],
      ['X','o','o','o','X','o','o','o','X','o','o','o','o','o','o','X','o','o','o','o','o','o','o','o','X','n','n'],
    ]
  }

  getFlagsConfig() {
    // prettier-ignore
    return [ // change n to '0'
          ['n','n','n','n','r','n','g','n','n','n','n','n','n','n','b','n','n','n','n','n','n','y','n','n','n','n','n'],
          ['n','n','n','n','r','n','g','n','n','y','y','y','y','y','yb','y','y','y','y','y','y','y','n','n','n','n','n'],
          ['n','n','n','n','r','n','g','n','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n','n'],
          ['n','n','n','n','r','n','g','n','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n','n'],
          ['n','n','n','n','r','n','g','n','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n','n'],
          ['y','y','y','y','ry','y','gy','y','y','y','n','n','n','n','b','b','n','n','n','n','n','n','n','n','n','n','n'],
          ['n','n','n','n','r','n','g','n','n','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n'],
          ['n','n','n','n','r','n','g','g','g','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n'],
          ['n','n','n','n','r','n','n','n','g','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','n'],
          ['o','o','o','o','ro','o','o','o','go','o','o','o','o','o','o','ob','o','o','o','o','o','o','o','o','o','n','n'],
        ]
  }

  getAreaToDigWithExcavatorConfig() {
    // prettier-ignore
    return [ // change n to '0'
            ['n','n','n','r','n','g','g','n','n','n','n','n','n','n','b','n','n','n','n','n','n','y','n','d','d','d','d'],
            ['n','n','d','d','d','d','d','d','d','d','d','d','d','d','d','d','d','d','d','d','d','y','n','d','d','d','d'],
            ['n','d','d','d','d','d','d','d','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','d','d','d'],
            ['d','d','d','d','d','d','d','d','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','d','d'],
            ['d','d','d','d','d','d','d','d','n','y','n','d','d','d','d','d','d','d','d','d','d','d','n','n','d','d','d'],
            ['d','d','d','d','d','d','d','d','y','y','n','d','d','d','d','d','d','d','d','d','d','d','n','d','d','d','d'],
            ['d','d','d','d','d','d','d','d','n','n','n','d','d','d','d','d','d','d','d','d','d','d','n','d','d','d','d'],
            ['n','d','d','d','d','d','d','d','g','n','n','d','d','d','d','d','d','d','d','d','d','d','n','d','d','d','d'],
            ['n','n','d','d','d','d','d','d','d','d','d','d','d','d','n','b','n','n','n','n','n','n','n','n','d','d','d'],
            ['o','o','o','o','ro','o','o','o','go','o','o','o','o','o','o','ob','o','o','o','o','o','o','o','o','o','d','d'],
        ]
  }

  getAreaToPlaceCement() {
    // prettier-ignore
    return [ 
      ['n','n','n','r','n','g','g','n','n','n','n','n','n','n','b','n','n','n','n','n','n','y','n','c','c','c','c'],
      ['n','n','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','y','n','c','c','n','c'],
      ['n','c','c','d','d','d','d','c','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','c','c','c'],
      ['n','c','d','d','d','d','d','c','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','c','c'],
      ['c','c','d','d','d','d','d','c','n','y','n','c','c','c','c','c','c','c','c','c','c','n','n','n','c','c','c'],
      ['c','d','d','d','d','d','d','c','y','y','n','c','n','n','n','n','n','n','n','n','c','n','n','c','c','f','c'],
      ['c','c','d','d','d','d','d','c','n','n','n','c','c','c','c','c','c','c','c','c','c','n','n','c','c','d','c'],
      ['n','c','c','d','d','d','d','c','g','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','c','d','c'],
      ['n','n','c','c','c','c','c','c','c','c','c','c','c','c','n','b','n','n','n','n','n','n','n','n','c','d','c'],
      ['o','o','o','o','ro','o','o','o','go','o','o','o','o','o','o','ob','o','o','o','o','o','o','o','o','o','c','c'],
   ]
  }

  getAreaToPlaceFoundations() {
    // prettier-ignore
    return [ 
    ['n','n','n','r','n','g','g','n','n','n','n','n','n','n','b','n','n','n','n','n','n','y','n','c','c','c','c'],
    ['n','n','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','c','y','n','c','c','f','c'],
    ['n','c','d','d','d','d','d','c','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','c','c'],
    ['n','c','d','d','d','d','d','c','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','c','c'],
    ['c','c','d','f','d','f','d','c','n','y','n','n','n','n','b','n','n','n','n','n','n','n','n','n','n','c','c'],
    ['c','d','d','d','d','d','d','c','y','y','n','c','f','c','c','f','c','c','f','c','c','n','n','c','c','f','c'],
    ['c','c','d','f','d','f','d','c','n','n','n','c','c','c','c','c','c','c','c','c','c','n','n','c','c','d','c'],
    ['n','c','c','d','d','d','d','c','g','n','n','n','n','n','n','b','n','n','n','n','n','n','n','n','c','d','c'],
    ['n','n','c','c','c','c','c','c','c','c','c','c','c','c','n','b','n','n','n','n','n','n','n','n','c','d','c'],
    ['o','o','o','o','ro','o','o','o','go','o','o','o','o','o','o','ob','o','o','o','o','o','o','o','o','o','c','c'],
    ]
  }
  getBuildAreasConfig() {
    return buildArea;
  }
  setupSafetyOfficer() {
    this.safetyOfficer = new Character(this, 320, 455, SAFETY_OFFICER);
    this.safetyOfficer.setFlipX(true);
    this.safetyOfficer.setScale(0.12);
    this.safetyOfficer.spine.setSkinByName("default");

    this.safetyOfficer.setDepth(311);
  }
  setUpLocator(x, y) {
    this.locator = new Character(this, x, y, LOCATOR);
    this.locator.approchCount = 0;
    this.locator.setFlipX(true);
    this.locator.setScale(0.12);
    this.locator.spine.setSkinByName("default");
    this.locator.setDepth(311);
  }

  addColliders(json, label = "Body") {
    var Body = Phaser.Physics.Matter.Matter.Body;
    var Composite = Phaser.Physics.Matter.Matter.Composite;
    var Parser = Phaser.Physics.Matter.PhysicsEditorParser;
    var shapes = json;
    var composite = Composite.create();

    for (var i = 0; i < shapes.fixtures.length; i++) {
      var body = Body.create({ isStatic: true });
      body.colliderName = label;
      Body.setParts(body, Parser.parseVertices(shapes.fixtures[i].vertices));
      Composite.addBody(composite, body);
    }

    this.matter.world.add(composite);
    return composite;
  }
  addOwnDepthSpine(x, y, key, animation, loop, skin) {
    const spine = this.add.spine(x, y, key, animation, loop);
    spine.setSkinByName(skin);
    spine.setDepth(spine.y);
    return spine;
  }

  addOwnDepthImage(x, y, image) {
    const img = this.add.image(x, y, image);
    img.setDepth(img.y);
    return img;
  }

  setupWorld() {
    this.matter.world.setBounds(0, 0, 1280, 720);
    this.matter.world.setGravity(0, 0, 0);
  }

  fadeIn() {
    this.blackOL = this.add
      .image(
        this.game.renderer.width / 2,
        this.game.renderer.height / 2,
        CST.COMMON_IMAGES.BLACK_OVERLAY
      )
      .setScale(50, 50)
      .setDepth(1200)
      .setOrigin(0.5, 0.5);
    Extention.fadeOut(this, this.blackOL, 1500);
  }

  getMapConfig() {
    return {
      mapImage: CST.MAP.MAP4,
      playerX: this.player.getPlayer().x * POSITION_MULTIPLYER,
      playerY: this.player.getPlayer().y * POSITION_MULTIPLYER,
      offsetX: 1200 + -960 * POSITION_MULTIPLYER,
      offsetY: -180 * POSITION_MULTIPLYER,
      worldMaxX: -915 + 4775 * 0.66,
    };
  }

  setUpPlayer() {
    this.player = new Character(this, 0, 0, SPINE_KEY + this.gender, 0, 55);
    this.player.getPlayer().setPosition(50, 500);

    this.player.addDefaultPPE(
      SpineCharacterData.getDefaultCostume(this.gender)
    );
    this.player.applyDefaultSkin(
      SpineCharacterData.getDefaultCostume(this.gender)
    );
    this.player.applySkins(SpineCharacterData.getCurrentCostumeWithoutPPE());
    // this.player.removeSkins([SpineCharacterData.getCurrentCostumeByType(SpineCharacterData.costumeTypes().SHOES)])
    this.player.applySkin(
      SpineCharacterData.getCurrentCostumeByType(
        SpineCharacterData.costumeTypes().VEST
      )
    );
    this.player.applySkin(
      SpineCharacterData.getCurrentCostumeByType(
        SpineCharacterData.costumeTypes().HAT
      )
    );
    this.player.applySkin(
      SpineCharacterData.getCurrentCostumeByType(
        SpineCharacterData.costumeTypes().SHOES,
        true
      )
    );
    this.player.applySkin(
      SpineCharacterData.getCurrentCostumeByType(
        SpineCharacterData.costumeTypes().GLASSES
      )
    );
    this.player.applySkin(
      SpineCharacterData.getCurrentCostumeByType(
        SpineCharacterData.costumeTypes().GLOVES
      )
    );
    this.player.setScale(0.21);
    this.player.addPhysics();
    this.player.setSpeed(PLAYER_SPEED);
    this.player.addInputEvents(this.eventManager);

    this.player.addThoughtBubble();
    this.player.bubble.setVisible(false);

    this.player.playAnimationByName(
      SpineCharacterData.AnimationName.Idle,
      true
    );

    window.player = this.player;
    window.camera = this.cameras.main;
  }

  addFloor() {
    let ground = this.add.image(
      0,
      this.game.renderer.height / 2,
      IMAGES.BIG_GROUND
    );
    ground.setPosition(
      ground.getBounds().width / 2,
      this.game.renderer.height / 2
    );
  }

  setController() {
    this.isMobile = Extention.isMobile(this);
    if (this.isMobile) {
      if (this.joyStick == null) {
        this.joyStick = new JoyStickController(this, this.eventManager);
        this.joyStick.setDepth(10000);
      }
    } else if (this.inputManager == null) {
      this.inputManager = new InputManager({
        scene: this,
        eventManager: this.eventManager,
      });
    }
  }

  reset() {}
}
