export default {
    types: ["STAIRCASE"],
    ladderConfig: [{
        startX:700, 
        startY:250, 
        config:[[1]]
             
    }],

 
}